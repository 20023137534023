import { Dialog, Drawer, Progress } from "@material-tailwind/react";
import {
  ICON_CLOSE_NO_CIIRCLE,
  ICON_INFO,
  ICON_NAVIGATE,
  ICON_PLUS,
  ICON_WARNING_CIRCLE,
} from "../../../utils/useIcons";
import { MTButton } from "../../../components/button/MTButton";
import {
  STYLE_ACCORDION_BODY_TAX_2,
  STYLE_BG_BLUE_DRAWER,
  STYLE_BG_BLUE_DRAWER_2,
  STYLE_BOX_DONATE,
  STYLE_CARD_BG,
  STYLE_GRAY_DRAWER_TAB,
  STYLE_ICON_CLOSE_DW,
  STYLE_IMG_DRAWER,
  STYLE_INPUT,
  STYLE_INPUT_INSET,
  STYLE_MAX_W_DRAWER,
  STYLE_MAX_W_DRAWER_TAX,
  STYLE_PARENT_BUTTON_DRAWER,
  STYLE_PARENT_BUTTON_DRAWER_2,
  STYLE_PARENT_BUTTON_DRAWER_3,
  STYLE_TITLE_DISPLAY,
  STYLE_TITLE_DRAWER,
} from "../../../utils/useStyle";
import { HeaderTitle } from "../../../components/header/Header";
import {
  CardDeduction,
  CardDeductionChild,
  CardMain,
} from "../../../components/card/CardMain";
import { useEffect, useMemo, useState } from "react";
import {
  calInsuarant,
  calInsuarant2,
  calPercentValue,
  calSum5Value,
  checkChild,
  checkError,
  checkIncome,
  checkIncome2,
  checkMinusMaxValue,
  checkSSF,
  checkSpouseIncome,
  deduction_default_result,
  formReset,
  formatChildReset,
  formatDeduction,
  formatDeductionReset,
  getInsurancePaid,
  getPercent,
  getcalPaid,
  getcalcRemainEducaid,
} from "../functions";
import { useTaxPlanContext } from "../Tax_Plan.Main";
import { PanelTax } from "../styles/Panel_Main";
import numeral, { Numeral } from "numeral";
import { useWindowSize } from "../../../utils/useWindowSize";
import { useIsMobile } from "../../../utils/useIsMobile";
import _, { max } from "lodash";
import { NumericFormat } from "react-number-format";
import { useFamilyStore } from "../../../_store/familyStore";
import { PopupMain } from "../../../components/popup/PopupMain";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";
import { useBalanceStore } from "../../../_store/balanceStore";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";

const generateSummary = (form) => {
  const result = formatDeduction(form);
  const sumResult = _?.sumBy(result, "amount");
  const sumResultExceptEducationDonate = _?.sumBy(
    result?.filter((el) => el?.key !== "education_donate"),
    "amount"
  );
  const sumResultExceptOtherDonate = _?.sumBy(
    result?.filter((el) => el?.key !== "other_donate"),
    "amount"
  );
  // console.log("result : ", result);
  return {
    result,
    sumResult,
    sumResultExceptEducationDonate,
    sumResultExceptOtherDonate,
  };
};

// Sum Except Education Donate || Sum Except Other Donate
const getValueremian = (form, key) => {
  let income = form?.tax_income;
  let deductAll = form?.[key];
  let results = income - 100000 - deductAll;
  // console.log("results", results);
  return results;
};

export const PayerIncome = ({ openInfo, setOpenInfo, setOpenDrawer }) => {
  const isMobile = useIsMobile();

  return (
    <div index={1}>
      {isMobile ? (
        <Drawer
          placement="bottom"
          open={openInfo}
          onClose={() => {
            // setOpenDrawer(true);
            setOpenInfo(false);
          }}
          className=" rounded-t-[40px] !max-h-[50vh] overflow-y-scroll "
        >
          <div className={``}>
            <div className=" w-[90px] h-[3px] rounded-full mx-auto bg-[#E0DFDF] mb-7 mt-5"></div>
            <img
              src="/images/img_popup.png"
              className="animate-bounce mx-auto w-64 h-auto"
              alt=""
            />
            <div className=" w-[61px] h-[3px] rounded-full mx-auto bg-maincyan mb-2 mt-3"></div>
            <div className="px-10 h-fit">
              <div className=" text-xl font-IBMPlexSansThai font-semibold text-mainblue text-center">
                ได้รับสิทธิลดหย่อน
              </div>
              <div className={` ${STYLE_TITLE_DRAWER} mt-1`}>
                {`60,000 `} บาท
              </div>
            </div>
            <img
              src="/images/bgblue.png"
              className={STYLE_BG_BLUE_DRAWER}
              alt=""
            />
          </div>
        </Drawer>
      ) : (
        <Dialog
          // onClose={() => {
          //   setOpenInfo(false);
          // }}
          open={openInfo}
          className="bg-blueopacity shadow-none p-4 rounded-[30px] font-IBMPlexSansThai"
        >
          <ICON_CLOSE_NO_CIIRCLE
            className={` ml-auto cursor-pointer `}
            onClick={() => {
              setOpenInfo(false);
            }}
          />
          <div className={``}>
            <img
              src="/images/img_popup.png"
              className="animate-bounce mx-auto w-64 h-auto mt-10"
              alt=""
            />
            <div className=" w-[61px] h-[3px] rounded-full mx-auto bg-maincyan mb-2 mt-3"></div>
            <div className="px-10 h-fit">
              <div className=" text-xl font-IBMPlexSansThai font-semibold text-mainblue text-center">
                ได้รับสิทธิลดหย่อน
              </div>
              <div className={` ${STYLE_TITLE_DRAWER} mt-1`}>
                {`60,000 `} บาท
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export const Spouse = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  isMarriage,
  setIsMarriage,
  ishasIncome,
  setIshasIncome,
  isSpouseDisable,
  setIsSpouseDisable,
}) => {
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_spouse: checkSpouseIncome(
          ishasIncome,
          isMarriage,
          isSpouseDisable
        ),
      });

      setForm({
        ...form,
        deduct_spouse: checkSpouseIncome(
          ishasIncome,
          isMarriage,
          isSpouseDisable
        ),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncome, isMarriage, isSpouseDisable]);

  const displayDeductionSpouse = ishasIncome ? (
    <div className=" text-xl font-IBMPlexSansThai font-semibold text-mainblue text-center ">
      ไม่ได้รับสิทธิลดหย่อน
    </div>
  ) : isMarriage ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>120,000 บาท</div>
    </div>
  ) : isSpouseDisable ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>120,000 บาท</div>
    </div>
  ) : (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>60,000 บาท</div>
    </div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div>
        <div className={STYLE_MAX_W_DRAWER_TAX}>
          <div className={`${STYLE_TITLE_DRAWER} `}>คู่สมรสมีรายได้หรือไม่</div>
          <div className={`flex gap-3 items-center justify-center mt-6`}>
            <MTButton
              text="มี"
              color={`border-maincyan ${ishasIncome && "bg-maincyan"}`}
              colortext={`text-maincyan font-medium  ${
                ishasIncome && "text-white"
              } `}
              variant="outlined"
              onClick={() => {
                setIshasIncome(true);
              }}
            />
            <MTButton
              text="ไม่มี"
              color={`border-maincyan ${!ishasIncome && "bg-maincyan"}`}
              colortext={`text-maincyan font-medium  ${
                !ishasIncome && "text-white"
              } `}
              variant="outlined"
              onClick={() => {
                setIshasIncome(false);
                setIsMarriage(true);
              }}
            />
          </div>
          {/* {ishasIncome ? (
            <div className=" p-2.5 rounded-[10px] border border-gray80 text-maingray text-md mt-[37px]">
              <div className=" flex  gap-1">
                <ICON_WARNING_CIRCLE
                  className={` w-6 h-6 text-mainyellow flex-none `}
                />
                <div className="mt-1">
                  ในกรณีคู่สมรสมีรายได้ทางเราจะรองรับ
                  การแยกยื่นภาษีกับคู่สมรสเท่านั้น ซึ่งการแยก
                  ยื่นจะทำให้คุณไม่ได้รับสิทธิลดหย่อนคู่สมรส
                </div>
              </div>
            </div>
          ) : (
            <div div className="mt-16">
              <div className={`${STYLE_TITLE_DRAWER} `}>
                <div>คู่สมรสมีบัตรประจำตัวผู้พิการหรือไม่</div>
              </div>

              <div className={`flex gap-3 items-center justify-center mt-6`}>
                <MTButton
                  text="ใช่"
                  color={`border-maincyan ${isMarriage ? "bg-maincyan" : ""}`}
                  colortext={`text-maincyan font-medium  ${
                    isMarriage ? "text-white" : ""
                  } `}
                  variant="outlined"
                  onClick={() => {
                    setIsMarriage(true);
                  }}
                />
                <MTButton
                  text="ไม่ใช่"
                  color={`border-maincyan ${!isMarriage && "bg-maincyan"}`}
                  colortext={`text-maincyan font-medium  ${
                    !isMarriage && "text-white"
                  } `}
                  variant="outlined"
                  onClick={() => {
                    setIsMarriage(false);
                    setIshasIncome(false);
                  }}
                />
              </div>
            </div>
          )} */}

          <div className="">
            {!isMarriage && (
              <div className={`${STYLE_TITLE_DRAWER} mt-16`}>
                <div>แพทย์รับรองว่าคู่สมรสทุพพลภาพหรือไม่</div>
                <div className={`flex gap-3 items-center justify-center mt-6`}>
                  <MTButton
                    text="ใช่"
                    color={`border-maincyan ${
                      isSpouseDisable ? "bg-maincyan" : ""
                    }`}
                    colortext={`text-maincyan font-medium  ${
                      isSpouseDisable ? "text-white" : ""
                    } `}
                    variant="outlined"
                    onClick={() => {
                      setIsSpouseDisable(true);
                      // setIshasIncome(true);
                      // setIsMarriage(true);
                    }}
                  />
                  <MTButton
                    text="ไม่ใช่"
                    color={`border-maincyan ${
                      !isSpouseDisable && "bg-maincyan"
                    }`}
                    colortext={`text-maincyan font-medium  ${
                      !isSpouseDisable && "text-white"
                    } `}
                    variant="outlined"
                    onClick={() => {
                      setIsSpouseDisable(false);
                      setIshasIncome(false);
                      setIsMarriage(false);
                      // setIshasIncome(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={STYLE_PARENT_BUTTON_DRAWER_2}>
          <div
            className={` ${
              ishasIncome ? "" : ""
            } animate-fade-up bg-grayopacity/40 w-full h-20  flex flex-col justify-center items-center p-[14px]`}
          >
            {displayDeductionSpouse}
          </div>
          <div className={STYLE_PARENT_BUTTON_DRAWER}>
            <MTButton
              text="ยกเลิก"
              color="border border-maincyan "
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              onClick={() => {
                setOpenDrawer(true);
                setOpenInfo(false);
              }}
            />
            <MTButton
              text="ยืนยัน"
              onClick={() => {
                setForm({
                  ...form,
                  deduct_spouse: checkSpouseIncome(
                    ishasIncome,
                    isMarriage,
                    isSpouseDisable
                  ),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }}
            />
          </div>
        </div>
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-20 -z-10`}
      />
    </Drawer>
  );
};

export const Child = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  ishasChild,
  setIshasChild,
}) => {
  const [showPopup, setShowPopup] = useState();
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  // const { chlidList, setChildList } = useTaxPlanContext();
  const chlidList = useFamilyStore((state) => state.chlidList);
  const setChildList = useFamilyStore((state) => state.setChildList);

  useEffect(() => {
    let ignore = false;
    if (chlidList?.length > 0) {
      setIshasChild(true);
    }
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({ ...form, deduct_child: checkChild(chlidList) });

      setForm({
        ...form,
        deduct_child: checkChild(chlidList),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [chlidList]);

  const sumDeductionChild = useMemo(() => checkChild(chlidList), [chlidList]);

  const displayDeductChild = ishasChild ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>
        {" "}
        {numeral(sumDeductionChild).format("0,0")} บาท
      </div>
    </div>
  ) : (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}>0 บาท</div>
    </div>
  );

  // console.log("openInfoPopUp", openInfoPopUp);
  return (
    <>
      <Drawer
        placement="bottom"
        open={openInfo}
        className=" !max-h-screen overflow-y-scroll"
      >
        <div className={` ${STYLE_MAX_W_DRAWER}  md:mt-32 mt-16 `}>
          <div className="flex items-center justify-between px-6  md:px-12  ">
            <div className="flex">
              <div className={`${STYLE_TITLE_DRAWER}  `}>ลูก</div>
              <div>
                <ICON_INFO
                  onClick={() => {
                    setOpenInfoPopUp(!openInfoPopUp);
                  }}
                  className={` w-4 h-4 text-maingray mt-1`}
                />
              </div>
            </div>

            <MTButton
              disabled={chlidList?.length === 3}
              width={`w-fit px-3  `}
              iconleft
              icon={<ICON_PLUS className="w-4 h-4 text-white flex-none mr-2" />}
              text={`เพิ่ม`}
              onClick={() => {
                if (chlidList.length === 0) {
                  setIshasChild(true);
                  setChildList([...chlidList, 30000]);
                } else {
                  setShowPopup(true);
                }
              }}
            />
          </div>
          {ishasChild ? (
            <div className="  px-6  md:px-12 ">
              {chlidList?.map((row, index) => {
                // console.log(row);
                return (
                  <div className=" my-5">
                    <CardDeductionChild
                      title={` คนที่ ${index + 1}`}
                      amount={numeral(Number(row))?.format("0,0")}
                      minus={true}
                      onClickDelete={() => {
                        const newChild = [...chlidList];
                        newChild.splice(index, 1);
                        setChildList(newChild);
                      }}
                      colorbg="bg-gray50"
                      hasicon={false}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className=" flex flex-col justify-center items-center mt-5">
              <img src="/images/no_child.svg" className="w-[115px] h-[115px]" />
              <div className="text-center text-gray80">
                ท่านยังไม่ได้เพิ่มข้อมูล
              </div>
            </div>
          )}

          {showPopup && (
            <div className=" px-6 lg:px-12  md:px-12 ">
              <div className=" text-center p-2.5 rounded-[10px] border border-gray80 text-maingray text-sm :text-md mt-[37px]">
                <div className=" flex  justify-center items-center gap-1 ">
                  <ICON_WARNING_CIRCLE
                    className={` w-6 h-6 text-mainyellow flex-none `}
                  />
                  <div className="mt-1 text-center">
                    บุตร (ตั้งแต่คนที่ 2 เป็นต้นไปที่เกิดใน
                  </div>
                </div>
                <div className=" mt-1">
                  {" "}
                  หรือหลังปี พ.ศ.2561) คนละ 60,000 บาท
                </div>
              </div>
              <div className={`flex gap-3 items-center justify-center mt-6`}>
                <MTButton
                  text="ใช่"
                  color={`border-maincyan ${chlidList ? "bg-maincyan" : ""}`}
                  colortext={`text-maincyan font-medium  ${
                    chlidList ? "text-white" : ""
                  } `}
                  variant="outlined"
                  onClick={() => {
                    setChildList([...chlidList, 60000]);
                    setShowPopup(false);
                  }}
                />
                <MTButton
                  text="ไม่ใช่"
                  color={`border-maincyan ${!chlidList && "bg-maincyan"}`}
                  colortext={`text-maincyan font-medium  ${
                    !chlidList && "text-white"
                  } `}
                  variant="outlined"
                  onClick={() => {
                    setChildList([...chlidList, 30000]);
                    setShowPopup(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className={STYLE_PARENT_BUTTON_DRAWER_2}>
          <div className={STYLE_GRAY_DRAWER_TAB}>{displayDeductChild}</div>
          <div className={STYLE_PARENT_BUTTON_DRAWER}>
            <MTButton
              text="ยกเลิก"
              color="border border-maincyan "
              colortext="text-maincyan group-hover:text-white"
              hovercolor="hover:bg-maincyan group "
              onClick={() => {
                setChildList([]);
                setForm((prev) => {
                  const newForm = { ...prev };
                  newForm.deduct_child = newForm.deduct_child_prev;
                  return newForm;
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }}
            />
            <MTButton
              text="ยืนยัน"
              onClick={() => {
                setForm({
                  ...form,
                  deduct_child: checkChild(chlidList),
                  deduct_child_prev: checkChild(chlidList),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }}
            />
          </div>
        </div>
        <PopupMain
          img={null}
          onClick={() => {
            setOpenInfoPopUp(false);
          }}
          open={openInfoPopUp}
          text={
            <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
              <div className="font-medium">หลักเกณฑ์การหักลดหย่อนบุตร</div>
              <div>
                - บุตรชอบด้วยกฎหมายของผู้มีเงินได้ หรือของคู่สมรส คนละ 30,000.00
                บาท
              </div>
              <div>
                - บุตรบุญธรรมของผู้มีเงินได้ คนละ 30,000.00 บาท
                แต่รวมกันได้ไม่เกิน 3 คน
              </div>
              - บุตรต้องไม่มีเงินได้ในปีภาษีนั้น ตั้งแต่ 30,000.00 บาทขึ้นไป
              ยกเว้นเงินได้จากเงินปันผล
              <div> - บุตรต้องอยู่ในความอุปการะเลี้ยงดูของผู้มีเงินได้ </div>
              <div>
                - เป็นผู้เยาว์ (อายุไม่ถึง 20 ปี) หรือผู้มีอายุไม่เกิน 25 ปี
                และยังศึกษาอยู่ในมหาวิทยาลัย หรือชั้นอุดมศึกษา (ปวส)
                หรือเป็นผู้ที่ศาลสั่งให้เป็นคนไร้ความสามารถหรือเสมือนไร้ความสามารถ
              </div>
              <div>
                - กรณีผู้มีเงินได้มิได้เป็นผู้อยู่ในประเทศไทย
                ให้หักลดหย่อนได้เฉพาะ สามีหรือภริยาและบุตรที่อยู่ในประเทศไทย
              </div>
              <div>
                - สำหรับบุตรคนที่ 2 เป็นต้นไป (ยกเว้นบุตรบุญธรรม)
                ที่เกิดในหรือหลัง พ.ศ. 2561 หักลดหย่อนได้เพิ่มอีก คนละ 30,000
                บาท
              </div>
            </div>
          }
        />

        <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
      </Drawer>
    </>
  );
};

export const Mother = ({
  openInfo,
  setOpenInfo,
  setMother,
  setForm,
  form,
  setOpenDrawer,
  ishasIncomeMother,
  setIshasIncomeMother,
}) => {
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_mother: checkIncome(ishasIncomeMother),
      });

      setForm({
        ...form,
        deduct_mother: checkIncome(ishasIncomeMother),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncomeMother]);

  const displayValue = ishasIncomeMother ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}> 30,000 บาท</div>
    </div>
  ) : (
    <div className={STYLE_TITLE_DISPLAY}>ไม่ได้รับสิทธิลดหย่อน</div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <div className="font-medium">
              หลักเกณฑ์การหักลดหย่อนค่าอุปการะเลี้ยงดูบิดา/มารดา
            </div>
            <div>
              {" "}
              - บิดา/มารดาต้องมีอายุตั้งแต่ 60 ปีขึ้นไป (การคำนวณอายุ
              ให้นำปีภาษี หักด้วย พ.ศ. เกิดของบิดา/มารดา)
            </div>
            <div>
              - บิดา/มารดา
              ต้องมีเลขประจำตัวประชาชนตามกฎหมายว่าด้วยการทะเบียนราษฎร
            </div>
            <div>- อยู่ในความอุปการะเลี้ยงดูของผู้มีเงินได้</div>
            <div>
              - หากภริยาแยกยื่นแบบเสียภาษี ให้ต่างฝ่ายต่างหักบิดา/มารดาของตน
            </div>
            <div>- บิดา/มารดาต้องไม่มีเงินได้ในปีภาษีนั้นเกิน 30,000 บาท</div>
            <div>- ผู้มีเงินได้หรือคู่สมรสต้องเป็นบุตรชอบด้วยกฎหมาย</div>
            <div>- บุตรบุญธรรมไม่มีสิทธิหักลดหย่อน</div>
            <div>
              - บุตรหลายคนอุปการะบิดา/มารดา ให้บุตรเพียงคนเดียวที่มีหลักฐาน
              (ล.ย.03) เป็นผู้มีสิทธิหักลดหย่อน
            </div>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <div className={`${STYLE_TITLE_DRAWER} flex justify-center w-full `}>
          อุปการะเลี้ยงดูแม่หรือไม่
          <ICON_INFO
            onClick={() => {
              console.log("click");
              setOpenInfoPopUp(!openInfoPopUp);
            }}
            className={` w-4 h-4 text-maingray mt-1`}
          />
        </div>
        <div className={`flex gap-3 items-center justify-center mt-6`}>
          <MTButton
            text="ใช่"
            color={`border-maincyan ${ishasIncomeMother && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              ishasIncomeMother && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeMother(true);
            }}
          />
          <MTButton
            text="ไม่"
            color={`border-maincyan ${!ishasIncomeMother && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              !ishasIncomeMother && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeMother(false);
            }}
          />
        </div>
      </div>
      <div className={STYLE_PARENT_BUTTON_DRAWER_3}>
        <div className={STYLE_GRAY_DRAWER_TAB}>{displayValue}</div>
        <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
          <MTButton
            text="ยกเลิก"
            color=" border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
          <MTButton
            text="ยืนยัน"
            onClick={() => {
              setForm({
                ...form,
                deduct_mother: checkIncome(ishasIncomeMother),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
        </div>
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Father = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  ishasIncomeFather,
  setIshasIncomeFather,
}) => {
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_father: checkIncome(ishasIncomeFather),
      });

      setForm({
        ...form,
        deduct_father: checkIncome(ishasIncomeFather),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncomeFather]);

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  const displayValue = ishasIncomeFather ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}> 30,000 บาท</div>
    </div>
  ) : (
    <div className={STYLE_TITLE_DISPLAY}>ไม่ได้รับสิทธิลดหย่อน</div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <div className="font-medium">
              หลักเกณฑ์การหักลดหย่อนค่าอุปการะเลี้ยงดูบิดา/มารดา
            </div>
            <div>
              {" "}
              - บิดา/มารดาต้องมีอายุตั้งแต่ 60 ปีขึ้นไป (การคำนวณอายุ
              ให้นำปีภาษี หักด้วย พ.ศ. เกิดของบิดา/มารดา)
            </div>
            <div>
              - บิดา/มารดา
              ต้องมีเลขประจำตัวประชาชนตามกฎหมายว่าด้วยการทะเบียนราษฎร
            </div>
            <div>- อยู่ในความอุปการะเลี้ยงดูของผู้มีเงินได้</div>
            <div>
              - หากภริยาแยกยื่นแบบเสียภาษี ให้ต่างฝ่ายต่างหักบิดา/มารดาของตน
            </div>
            <div>- บิดา/มารดาต้องไม่มีเงินได้ในปีภาษีนั้นเกิน 30,000 บาท</div>
            <div>- ผู้มีเงินได้หรือคู่สมรสต้องเป็นบุตรชอบด้วยกฎหมาย</div>
            <div>- บุตรบุญธรรมไม่มีสิทธิหักลดหย่อน</div>
            <div>
              - บุตรหลายคนอุปการะบิดา/มารดา ให้บุตรเพียงคนเดียวที่มีหลักฐาน
              (ล.ย.03) เป็นผู้มีสิทธิหักลดหย่อน
            </div>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <div className={`${STYLE_TITLE_DRAWER} flex-none`}>
          <div className={`${STYLE_TITLE_DRAWER} flex justify-center w-full `}>
            อุปการะเลี้ยงดูพ่อหรือไม่
            <ICON_INFO
              onClick={() => {
                console.log("click");
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
        </div>
        <div className={`flex gap-3 items-center justify-center mt-6`}>
          <MTButton
            text="ใช่"
            color={`border-maincyan ${ishasIncomeFather && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              ishasIncomeFather && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeFather(true);
            }}
          />
          <MTButton
            text="ไม่"
            color={`border-maincyan ${!ishasIncomeFather && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              !ishasIncomeFather && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeFather(false);
            }}
          />
        </div>
      </div>
      <div className={STYLE_PARENT_BUTTON_DRAWER_3}>
        <div className={STYLE_GRAY_DRAWER_TAB}>{displayValue}</div>
        <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
          <MTButton
            text="ยกเลิก"
            color=" border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
          <MTButton
            text="ยืนยัน"
            onClick={() => {
              setForm({
                ...form,
                deduct_father: checkIncome(ishasIncomeFather),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
        </div>
      </div>

      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Disable = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  ishasIncomeDisable,
  setIshasIncomeDisable,
}) => {
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_disable_people: checkIncome2(ishasIncomeDisable),
      });

      setForm({
        ...form,
        deduct_disable_people: checkIncome2(ishasIncomeDisable),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncomeDisable]);

  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  const displayValue = ishasIncomeDisable ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}> 60,000 บาท</div>
    </div>
  ) : (
    <div className={STYLE_TITLE_DISPLAY}>ไม่ได้รับสิทธิลดหย่อน</div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <div className="font-medium">
              หลักเกณฑ์การหักลดหย่อนค่าอุปการะคนพิการ/ทุพพลภาพ
            </div>
            <div>
              - คนพิการต้องมีบัตรประจำตัวตามกฎหมายว่าด้วยการส่งเสริมและ
              พัฒนาคุณภาพชีวิตคนพิการ
            </div>
            <div>
              - ผู้ใช้สิทธิต้องมีชื่อเป็นผู้ดูแลในบัตรประจำตัวของคนพิการ
            </div>
            <div>
              - คนทุพพลภาพต้องเป็นกรณีที่แพทย์ได้ตรวจและมีความเห็นว่า
              ทุพพลภาพมาแล้วไม่น้อยกว่า 180 วัน
            </div>
            <div>- คนพิการ/ทุพพลภาพต้องมีเงินได้ไม่เกิน 30,000 บาท</div>
            <div>
              - การหักลดหย่อนต้องมีหนังสือรับรอง (ล.ย.04-กรณีคนพิการ,
              ล.ย.04.1-กรณีคนทุพพลภาพ)
            </div>
          </div>
        }
      />
      <div className="mt-16">
        <div className={`${STYLE_TITLE_DRAWER} `}>
          ผู้พิการท่านนี้มี
          <div className="flex justify-center">
            บัตรประจำตัวผู้พิการหรือไม่{" "}
            <ICON_INFO
              onClick={() => {
                console.log("click");
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
        </div>
        <div className={`flex gap-3 items-center justify-center mt-6`}>
          <MTButton
            text="มี"
            color={`border-maincyan ${ishasIncomeDisable && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              ishasIncomeDisable && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeDisable(true);
            }}
          />
          <MTButton
            text="ไม่มี"
            color={`border-maincyan ${!ishasIncomeDisable && "bg-maincyan"}`}
            colortext={`text-maincyan font-medium  ${
              !ishasIncomeDisable && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeDisable(false);
            }}
          />
        </div>
        {ishasIncomeDisable ? (
          ""
        ) : (
          <div className=" px-10">
            <div className=" p-2.5 rounded-[10px] border border-gray80 text-maingray text-md mt-7">
              <div className=" flex items-center gap-1">
                <ICON_WARNING_CIRCLE
                  className={` w-6 h-6 text-mainyellow flex-none `}
                />
                <div className="mt-1">
                  ไม่ได้รับสิทธิลดหย่อนภาษีเนื่องจาก
                  <div>ผู้พิการท่านนี้ไม่มีบัตรประจำตัวผู้พิการ</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={STYLE_PARENT_BUTTON_DRAWER_3}>
        <div className={STYLE_GRAY_DRAWER_TAB}>{displayValue}</div>
        <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
          <MTButton
            text="ยกเลิก"
            color=" border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
          <MTButton
            text="ยืนยัน"
            onClick={() => {
              setForm({
                ...form,
                deduct_disable_people: checkIncome2(ishasIncomeDisable),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
        </div>
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Disabilities = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
  ishasIncomeDisabilities,
  setIshasIncomeDisabilities,
}) => {
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const {
        result,
        sumResult,
        sumResultExceptEducationDonate,
        sumResultExceptOtherDonate,
      } = generateSummary({
        ...form,
        deduct_disabilities_people: checkIncome2(ishasIncomeDisabilities),
      });

      setForm({
        ...form,
        deduct_disabilities_people: checkIncome2(ishasIncomeDisabilities),
        deduct_summary: sumResult,
        deduct_summary_except_education_donate: sumResultExceptEducationDonate,
        deduct_summary_except_other_donate: sumResultExceptOtherDonate,
      });
    }
    return () => {
      ignore = true;
    };
  }, [ishasIncomeDisabilities]);

  const displayValue = ishasIncomeDisabilities ? (
    <div>
      <div className={STYLE_TITLE_DISPLAY}>ได้รับสิทธิลดหย่อน</div>
      <div className={` ${STYLE_TITLE_DRAWER} `}> 60,000 บาท</div>
    </div>
  ) : (
    <div className={STYLE_TITLE_DISPLAY}>ไม่ได้รับสิทธิลดหย่อน</div>
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <div className="font-medium">
              หลักเกณฑ์การหักลดหย่อนค่าอุปการะคนพิการ/ทุพพลภาพ
            </div>
            <div>
              - คนพิการต้องมีบัตรประจำตัวตามกฎหมายว่าด้วยการส่งเสริมและ
              พัฒนาคุณภาพชีวิตคนพิการ
            </div>
            <div>
              - ผู้ใช้สิทธิต้องมีชื่อเป็นผู้ดูแลในบัตรประจำตัวของคนพิการ
            </div>
            <div>
              - คนทุพพลภาพต้องเป็นกรณีที่แพทย์ได้ตรวจและมีความเห็นว่า
              ทุพพลภาพมาแล้วไม่น้อยกว่า 180 วัน
            </div>
            <div>- คนพิการ/ทุพพลภาพต้องมีเงินได้ไม่เกิน 30,000 บาท</div>
            <div>
              - การหักลดหย่อนต้องมีหนังสือรับรอง (ล.ย.04-กรณีคนพิการ,
              ล.ย.04.1-กรณีคนทุพพลภาพ)
            </div>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <div className={`${STYLE_TITLE_DRAWER}  `}>
          ผู้พิการท่านนี้มีบัตรประจำตัว
          <div className="flex justify-center">
            ผู้ทุพพลภาพหรือไม่{" "}
            <ICON_INFO
              onClick={() => {
                console.log("click");
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
        </div>
        <div className={`flex gap-3 items-center justify-center mt-6`}>
          <MTButton
            text="ใช่"
            color={`border-maincyan ${
              ishasIncomeDisabilities && "bg-maincyan"
            }`}
            colortext={`text-maincyan font-medium  ${
              ishasIncomeDisabilities && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeDisabilities(true);
            }}
          />
          <MTButton
            text="ไม่"
            color={`border-maincyan ${
              !ishasIncomeDisabilities && "bg-maincyan"
            }`}
            colortext={`text-maincyan font-medium  ${
              !ishasIncomeDisabilities && "text-white"
            } `}
            variant="outlined"
            onClick={() => {
              setIshasIncomeDisabilities(false);
            }}
          />
        </div>
        {ishasIncomeDisabilities ? (
          ""
        ) : (
          <div className="">
            <div className=" p-2.5 rounded-[10px] border border-gray80 text-maingray text-md mt-7">
              <div className=" flex items-center gap-1">
                <ICON_WARNING_CIRCLE
                  className={` w-6 h-6 text-mainyellow flex-none `}
                />
                <div className="mt-1">
                  ไม่ได้รับสิทธิลดหย่อนภาษีเนื่องจาก
                  <div>ผู้พิการท่านนี้ไม่มีบัตรประจำตัวผู้พิการ</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={STYLE_PARENT_BUTTON_DRAWER_3}>
        <div className={STYLE_GRAY_DRAWER_TAB}>{displayValue}</div>
        <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
          <MTButton
            text="ยกเลิก"
            color=" border border-maincyan "
            colortext="text-maincyan group-hover:text-white"
            hovercolor="hover:bg-maincyan group "
            onClick={() => {
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
          <MTButton
            text="ยืนยัน"
            onClick={() => {
              setForm({
                ...form,
                deduct_disabilities_people: checkIncome2(
                  ishasIncomeDisabilities
                ),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }}
          />
        </div>
      </div>

      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Parents = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    // if (Number(output) > 15000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, deduct_insurance_premiums_parents: output });

    setForm({
      ...form,
      deduct_insurance_premiums_parents: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.deduct_insurance_premiums_parents, 15000),
    [form?.deduct_insurance_premiums_parents, 15000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.deduct_insurance_premiums_parents, 15000),
    [form?.deduct_insurance_premiums_parents, 15000]
  );

  // console.log("form", form?.deduct_insurance_premiums_parents);
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={6}
          title="เบี้ยประกันสุขภาพบิดามารดา"
          remain={displayRemain}
          value={form.deduct_insurance_premiums_parents}
          maxvalue={15000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance.png"
          error={form?.deduct_insurance_premiums_parents > 15000 ? true : false}
          errortext={`15,000`}
          infoPopUpHeader={`เบี้ยประกันสุขภาพบิดามารดา`}
          infoPopUpText={
            <div>
              <div>
                - ผู้มีเงินได้จ่ายค่าเบี้ยประกันให้บิดา/มารดาของตน และบิดา/มารดา
                ของคู่สมรสที่ไม่มีเงินได้ ให้ได้รับยกเว้นตามจำนวนที่จ่ายจริง
                แต่ไม่เกิน 15,000 บาท
              </div>
              <div>
                - บิดา/มารดา
                ต้องมีเลขประจำตัวประชาชนตามกฎหมายว่าด้วยการทะเบียนราษฎร
              </div>
              <div>
                - ต้องเป็นเบี้ยประกันสุขภาพ ไม่ใช่เบี้ยประกันชีวิต
                (หากเป็นกรมธรรม์ประกันชีวิต และมีการประกันสุขภาพเพิ่มเติม
                ไม่สามารถหักลดหย่อน)
              </div>
              <div>- บิดา/มารดาต้องไม่มีเงินได้ในปีภาษีนั้นเกิน 30,000 บาท</div>
              <div>- ผู้มีเงินได้หรือคู่สมรสต้องเป็นบุตรชอบด้วยกฎหมาย</div>
              <div>- บุตรบุญธรรมไม่มีสิทธิยกเว้นฯ</div>
              <div>
                - ภริยาไม่ใช้สิทธิแยกยื่นแบบเสียภาษี หรือใช้สิทธิก็ตาม
                ให้ต่างฝ่ายต่างได้รับยกเว้นฯ ค่าเบี้ยประกันสุขภาพบิดา/มารดาของตน
              </div>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER}`}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.deduct_insurance_premiums_parents =
                newForm.deduct_insurance_premiums_parents_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.deduct_insurance_premiums_parents > 15000) {
              return;
            } else {
              setForm({
                ...form,
                deduct_insurance_premiums_parents: Number(
                  form.deduct_insurance_premiums_parents
                ),
                deduct_insurance_premiums_parents_prev: Number(
                  form.deduct_insurance_premiums_parents
                ),
              });
            }
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const InsuranceLife = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceLifeChange = (e) => {
    const value = e.target.value;
    // if (isNaN(e.target.value)) return;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (e.target.value > 100000) return;
    // if (output + Number(form?.insurance_health) > 100000) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, insurance_life: output });

    setForm({
      ...form,
      insurance_life: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const AllInsurance = () => {
    let sum = 0;
    sum = 100000 - Number(form?.insurance_health);
    return sum;
  };

  const displayRemain = useMemo(
    () => calInsuarant(form?.insurance_life, form?.insurance_health),
    [form?.insurance_life, form?.insurance_health]
  );

  const getPercentBar = useMemo(
    () => getPercent(form?.insurance_life, form?.insurance_life_health),
    [form?.insurance_life, form?.insurance_life_health]
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setForm({
        ...form,
        insurance_life_health: AllInsurance(),
      });
    }
    return () => {
      ignore = true;
    };
  }, [form?.insurance_life_health]);

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="ประกันชีวิต"
          remain={displayRemain}
          value={form.insurance_life}
          maxvalue={
            form?.insurance_health === 0 || form?.insurance_health === ""
              ? 100000
              : form?.insurance_life_health
          }
          valueprogress={getPercentBar}
          onChange={onInsuranceLifeChange}
          img="/images/health-insurance-2.png"
          error={
            form?.insurance_life > 100000 ||
            form?.insurance_health + form?.insurance_life > 100000
              ? true
              : false
          }
          // full={
          //   form?.insurance_health + form?.insurance_life > 100000
          //     ? true
          //     : false
          // }
          errortext={displayRemain === 0 ? `100,000` : 0}
          etc
          textetc={`เมื่อรวมกับประกันสุขภาพ จะต้องไม่เกิน 100,000 บาท`}
          errorstyle1={
            form?.insurance_life > 100000 ||
            form?.insurance_health + form?.insurance_life > 100000
              ? "border-red-500"
              : ""
          }
          infoPopUpHeader="หลักเกณฑ์การลดหย่อนเบี้ยประกันชีวิต"
          infoPopUpText={
            <div>
              <div>
                {" "}
                - หักเป็นค่าลดหย่อนตามจำนวนที่จ่ายจริงแต่ไม่เกิน 10,000 บาท
                สำหรับเบี้ยประกันส่วนที่เกิน 10,000 แต่ไม่เกิน 90,000 บาท
                หักเป็นรายการยกเว้น ซึ่งต้องไม่เกินจากจำนวนเงินได้เมื่อหัก
                ค่าใช้จ่ายแล้ว (มีผลเท่ากับว่าเบี้ยประกันชีวิตให้หักลดหย่อนได้
                ตามจำนวนที่จ่ายจริงแต่ไม่เกิน 100,000 บาท)
              </div>
              <div>
                {" "}
                - เป็นการประกันชีวิต ที่กรมธรรม์มีกำหนดเวลา 10 ปีขึ้นไป
              </div>
              <div>
                {" "}
                - หากมีผลประโยชน์ตอบแทนคืนทุกปี (ไม่รวมเงินปันผลตามกรมธรรม์)
                ต้องไม่เกินร้อยละ 20 ของเบี้ยประกันชีวิตรายปี
              </div>
              <div>
                -
                ต้องแจ้งความประสงค์ที่จะใช้สิทธิยกเว้นภาษีเงินได้ต่อบริษัทประกันชีวิต
                หรือบริษัทประกันวินาศภัยที่ได้เอาประกันไว้ โดยบริษัทประกันฯ
                ต้องส่งข้อมูลของผู้เอาประกันด้วยข้อมูลอิเล็กทรอนิกส์
                ตามประกาศอธิบดีกรมสรรพากรเกี่ยวกับ ภาษีเงินได้ (ฉบับที่ 385)
              </div>
              <div>
                *** หากทำประกันชีวิตให้บุตร หรือ บิดา/มารดา
                ไม่สามารถหักลดหย่อนได้ ***
              </div>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.insurance_life = newForm.insurance_life_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.insurance_life > 100000) {
              return;
            } else {
              setForm({
                ...form,
                insurance_life: Number(form.insurance_life),
                insurance_life_prev: Number(form.insurance_life),
              });
            }
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const InsuaranceHealth = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    // if (output > 25000) return;
    // if (output > 100000 - form?.insurance_life) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, insurance_health: output });

    setForm({
      ...form,
      insurance_health: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.insurance_health, 25000),
    [form?.insurance_health, 25000]
  );

  const displayAllRemain = useMemo(
    () => calInsuarant(form.insurance_life, form?.insurance_health),
    [form?.insurance_life, form?.insurance_health]
  );

  const displayRemain = () => {
    let results = 0;
    results = 25000 - form.insurance_health;
    return results;
  };
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={6}
          title="ประกันสุขภาพ"
          remain={displayAllRemain === 0 ? 0 : displayRemain()}
          value={form.insurance_health}
          maxvalue={25000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-2.png"
          error={Number(form?.insurance_health) > 25000 ? true : false}
          errortext={`25,000`}
          full={displayAllRemain === 0 ? true : false}
          disabled={displayAllRemain < 0}
          etc
          textetc={`เมื่อรวมกับประกันชีวิต จะต้องไม่เกิน 100,000 บาท`}
          infoPopUpHeader="หลักเกณฑ์การยกเว้นเงินได้สำหรับเบี้ยประกันสุขภาพ"
          infoPopUpText={
            <div>
              <div>
                -
                เบี้ยประกันสุขภาพที่จ่ายให้แก่บริษัทประกันชีวิตหรือบริษัทประกันวินาศภัยที่ประกอบกิจการในราชอาณาจักร
              </div>
              <div>- หักลดหย่อนได้ตามที่จ่ายจริง แต่ไม่เกิน 25,000 บาท</div>
              <div>
                - เมื่อรวมกับเบี้ยประกันชีวิตแล้วจะต้องไม่เกิน 100,000 บาท
              </div>
              <div>
                - ทั้งนี้ ให้เป็นไปตามประกาศกฏกระทรวง(ฉบับที่ 365) และหลักเกณฑ์
                วิธีการและเงื่อนไข
              </div>
              <div>
                -
                ต้องแจ้งความประสงค์ที่จะใช้สิทธิยกเว้นภาษีเงินได้ต่อบริษัทประกันชีวิต
                หรือบริษัทประกันวินาศภัยที่ได้เอาประกันไว้ โดยบริษัทประกันฯ
                ต้องส่งข้อมูลของผู้เอาประกันด้วยข้อมูลอิเล็กทรอนิกส์
                ตามประกาศอธิบดีกรมสรรพากรเกี่ยวกับภาษีเงินได้ (ฉบับที่ 383)
              </div>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.insurance_health = newForm.insurance_health_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.insurance_health > 25000) {
              return;
            } else {
              setForm({
                ...form,
                insurance_health: Number(form.insurance_health),
                insurance_health_prev: Number(form.insurance_health),
              });
            }
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const InsuranceAnnuity = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const onInsuranceAnnuityChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 200000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, insurance_annuity: output });

    setForm({
      ...form,
      insurance_annuity: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const checkSum = useMemo(
    () =>
      calSum5Value(
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value
      ),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
    ]
  );

  const maxvalue = useMemo(() => {
    return form?.tax_income * 0.3 > 200000 ? 200000 : form?.tax_income * 0.3;
  }, [balanceData]);

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  const displayRemain = () => {
    let results = 0;
    results = maxvalue - form.insurance_annuity;
    return results;
  };

  const getPercentBar = useMemo(
    () => getPercent(form.insurance_annuity, maxvalue),
    [form?.insurance_annuity, maxvalue]
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="ประกันชีวิตแบบบำนาญ"
          remain={displayRemain()}
          value={form.insurance_annuity}
          maxvalue={maxvalue}
          valueprogress={getPercentBar}
          onChange={onInsuranceAnnuityChange}
          img="/images/health-insurance-2.png"
          error={form?.insurance_annuity > maxvalue}
          // errortext={`200,000`}
          errortext={maxvalue}
          etc
          textetc={`เบี้ยประกันชีวิตแบบบำนาญสูงสุด 200,000 บาทต่อปีและไม่เกินร้อยละ 30 ของรายได้พึงประเมิน`}
          textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000 บาท`}
          errorstyle2={checkSum > 500000 ? "text-red-500" : ""}
          infoPopUpHeader="หลักเกณฑ์การยกเว้นเงินได้สำหรับเบี้ยประกันแบบบำนาญ"
          infoPopUpText={
            <div>
              <div>
                - ให้ใช้สิทธิลดหย่อนเบี้ยประกันชีวิตก่อน
                หากเบี้ยประกันชีวิตยังไม่เกิน 100,000 บาท
                ให้นำเบี้ยประกันแบบบำนาญไปหักให้เต็มจำนวน 100,000 บาท
              </div>
              <div>
                - เฉพาะเบี้ยประกันแบบบำนาญหักเป็นรายการยกเว้นได้ไม่เกินร้อยละ 15
                ของเงินได้ แต่ไม่เกิน 200,000 บาท
              </div>
              <div>- เบี้ยประกันแบบบำนาญที่ยกเว้นนั้น เมื่อรวมกับ</div>
              <div>(1) เงินสะสมกองทุนสำรองเลี้ยงชีพ</div>
              <div>(2) กบข.</div>
              <div>(3) เงินสะสมกองทุนสงเคราะห์ครูโรงเรียนเอกชน</div>
              <div>(4) กองทุนการออมแห่งชาติ</div>
              <div>(5) กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)</div>
              <div>(6) ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)</div>
              <div>แล้วต้องไม่เกิน 500,000 บาท</div>
              <div>
                - กรณีคู่สมรสไม่มีเงินได้ แต่มีการประกันแบบบำนาญไว้
                หากความเป็นสามีภริยามีอยู่ตลอดปีภาษี
                ให้ผู้มีเงินได้หักลดหย่อนเบี้ยประกันบำนาญของคู่สมรสได้ตามที่จ่ายจริงแต่ไม่เกิน
                10,000 บาท ซึ่งจำนวนดังกล่าวให้รวมถึงเบี้ยประกันชีวิตด้วย
                (ถ้ามี) โดยบันทึกข้อมูลในช่องเบี้ยประกันชีวิต
              </div>
              <div>- ประกันแบบบำนาญ ที่กรมธรรม์มีกำหนดเวลา 10 ปีขึ้นไป</div>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            ** ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>

      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.insurance_annuity = newForm.insurance_annuity_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.insurance_annuity > 200000) {
              return;
            } else {
              const { amouth } = checkError(form);
              if (amouth) {
                return setError(true);
              } else {
                setForm({
                  ...form,
                  insurance_annuity: Number(form.insurance_annuity),
                  insurance_annuity_prev: Number(form.insurance_annuity),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }
            }
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER_2} />
    </Drawer>
  );
};

export const FundProvident = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_provident: output });

    setForm({
      ...form,
      fund_provident: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getValue = useMemo(
    () => calPercentValue(form.tax_income, 15),
    [form?.tax_income, 15]
  );

  // Get Remain
  const getRemainValue = getValue - form?.fund_provident;

  const getNotSurplus = () => {
    let value = form?.fund_provident;
    let result = 0;
    if (value <= 10000) {
      result = value;
    } else if (value > 10000) {
      result = 10000;
    }
    return result;
  };

  const getSurplus = () => {
    let value = form?.fund_provident;
    let result = 0;
    if (value > 10000) {
      result = value - 10000;
    }
    return result;
  };

  const getPercentBar = useMemo(
    () => getPercent(form.fund_provident, getValue),
    [form?.fund_provident, getValue]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_provident, 10000),
    [form?.fund_provident, 10000]
  );

  const checkSum = useMemo(
    () =>
      calSum5Value(
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value
      ),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
    ]
  );

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="เงินสะสม"
          title2="กองทุนสำรองเลี้ยงชีพ"
          // subtitle={`(ส่วนที่ไม่เกิน 10,000 บาท)`}
          remain={getRemainValue}
          value={form?.fund_provident}
          maxvalue={getValue}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_provident > getValue ? true : false}
          errortext={getValue}
          etc
          textetc={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000 บาท`}
          errorstyle2={checkSum > 500000 ? "text-red-500" : ""}
          panel2={
            <div className="  w-full px-4  mt-5 text-maingray ">
              <div className=" flex justify-between items-center ">
                <div className=" ">ส่วนที่ไม่เกิน 10,000 บาท</div>
                <div className=" font-semibold text-mainblue">
                  {numeral(getNotSurplus()).format("0,0")} บาท
                </div>
              </div>
              <div className=" flex justify-between items-center ">
                <div className=" ">ส่วนที่เกิน 10,000 บาท</div>
                <div className=" font-semibold text-mainblue">
                  {numeral(getSurplus()).format("0,0")} บาท
                </div>
              </div>
            </div>
          }
          infoPopUpHeader="เงินสะสมกองทุนสำรองเลี้ยงชีพ"
          infoPopUpText={
            <div>
              <div>
                หักได้ตามที่จ่ายจริงแต่ไม่เกิน 15% ของค่าจ้าง โดย 10,000 บาทแรก
                หักเป็นรายการลดหย่อน ส่วนที่เกิน 10,000 บาท แต่ไม่เกิน 490,000
                บาท หักเป็นรายการยกเว้นเงินได้
              </div>
              <div>
                ***เงินสะสมกองทุนสำรองเลี้ยงชีพที่ได้รับลดหย่อนและยกเว้น
                เมื่อรวมกับ
              </div>
              <div>(1) กบข.</div>
              <div>(2) กองทุนสงเคราะห์ครูโรงเรียนเอกชน</div>
              <div>(3) กองทุนการออมแห่งชาติ</div>
              <div>(4) เบี้ยประกันแบบบำนาญ</div>
              <div>(5) กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)</div>
              <div>(6) ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)</div>
              <div>แล้วต้องไม่เกิน 500,000 บาท ***</div>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            *ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>

      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_provident = newForm.fund_provident_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />

        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.fund_provident > getValue) {
              return;
            } else {
              const { amouth } = checkError(form);
              if (amouth) {
                return setError(true);
              } else {
                setForm({
                  ...form,
                  fund_provident: Number(form.fund_provident),
                  fund_provident_prev: Number(form.fund_provident),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }
            }
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER_2} />
    </Drawer>
  );
};

export const FundNSF = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 13200) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_nsf: output });

    setForm({
      ...form,
      fund_nsf: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.fund_nsf, 13200),
    [form?.fund_nsf, 13200]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_nsf, 13200),
    [form?.fund_nsf, 13200]
  );
  const checkSum = useMemo(
    () =>
      calSum5Value(
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value
      ),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
    ]
  );

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={6}
          title="เงินสะสมการออมแห่งชาติ"
          remain={displayRemain}
          value={form.fund_nsf}
          maxvalue={13200}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_nsf > 13200 ? true : false}
          errortext={13200}
          etc
          textetc={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000 บาท`}
          errorstyle1={checkSum > 500000 ? "text-red-500" : ""}
          infoPopUpHeader="หลักเกณฑ์การหักเงินสะสมกองทุนการออมแห่งชาติ"
          infoPopUpText={
            <div>
              <div>
                - หักได้ตามที่จ่ายจริง แต่ไม่เกิน 30,000 บาท
                โดยหักเป็นรายการยกเว้นเงินได้
              </div>
              <div>
                ***เงินสะสมกองทุนการออมแห่งชาติที่ได้รับยกเว้น เมื่อรวมกับ
              </div>
              <div>(1) กองทุนสำรองเลี้ยงชีพ</div>
              <div>(2) กบข.</div>
              <div>(3) กองทุนสงเคราะห์ครูโรงเรียนเอกชน</div>
              <div>(4) กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)</div>
              <div>(5) เบี้ยประกันแบบบำนาญ</div>
              <div>(6) ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)</div>
              <div>แล้วต้องไม่เกิน 500,000 บาท ***</div>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            *ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_nsf = newForm.fund_nsf_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.fund_nsf > 13200) {
              return;
            } else {
              const { amouth } = checkError(form);
              if (amouth) {
                return setError(true);
              } else {
                setForm({
                  ...form,
                  fund_nsf: Number(form.fund_nsf),
                  fund_nsf_prev: Number(form.fund_nsf),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }
            }
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const FundESG = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_esg: output });

    setForm({
      ...form,
      fund_esg: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.fund_esg, 100000),
    [form?.fund_esg, 100000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_esg, 300000),
    [form?.fund_esg, 300000]
  );

  const checkError = () => {
    const amouth = form?.fund_esg > 100000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG)"
          remain={displayRemain}
          value={form.fund_esg}
          maxvalue={300000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_esg > 300000 ? true : false}
          errortext={300000}
          paidPerYear={""}
          paidPerYearBorder={null}
          paidPerYearMargin={null}
          infoPopUpHeader={"หลักเกณฑ์การหักลดหย่อน Thai ESG"}
          infoPopUpText={
            <div className="flex flex-col">
              <div>
                {" "}
                - ให้หักเป็นรายการยกเว้นในอัตราไม่เกินร้อยละ 30
                ของเงินได้พึงประเมิน แต่ไม่เกิน 100,000 บาท สำหรับปีภาษีนั้น
              </div>
              <div>
                {" "}
                - ซื้อหน่วยลงทุนในกองทุนรวมไทยเพื่อความยั่งยืน Thai ESG
                ตั้งแต่วันที่ 21 พฤศจิกายน พ.ศ. 2566 ถึงวันที่ 31 ธันวาคม พ.ศ.
                2575
              </div>
              <div>
                - ต้องไม่ใช่ห้างหุ้นส่วนสามัญ
                หรือคณะบุคคลที่มิใช่นิติบุคคลและกองมรดกที่ยังไม่ได้แบ่ง
              </div>
              <div>
                -
                ต้องแจ้งความประสงค์ที่จะใช้สิทธิต่อบริษัทหลักทรัพย์จัดการกองทุนรวมที่ตนได้ซื้อหน่วยลงทุน
                เพื่อนำส่งข้อมูลอิเล็กทรอนิกส์ตามรูปแบบที่สรรพากรกำหนด
              </div>
              <div>
                - ต้องถือไว้ไม่น้อยกว่า 8 ปีนับตั้งแต่วันซื้อหน่วยลงทุนครั้งแรก
                หากผิดเงื่อนไขต้องยื่นแบบเพิ่มเติมและรับผิดเงินเพิ่ม
                เว้นแต่กรณีที่ผู้มีเงินได้ไถ่ถอนหน่วยลงทุนในกองทุนรวมเพื่อความยั่งยืนนั้นเพราะเหตุทุพพลภาพหรือตาย
              </div>
              <div>
                - ให้เป็นไปตามหลักเกณฑ์ วิธีการและเงื่อนไข
                ตามประกาศอธิบดีกรมสรรพากร
              </div>
            </div>
          }
          // etc
          // textetc={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000 บาท`}
          // errorstyle1={checkSum > 500000 ? "text-red-500" : ""}
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_esg = newForm.fund_esg_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.fund_esg > 100000) {
              return;
            } else {
              const { amouth } = checkError(form);
              if (amouth) {
                return setError(true);
              } else {
                setForm({
                  ...form,
                  fund_esg: Number(form.fund_esg),
                  fund_esg_prev: Number(form.fund_esg),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }
            }
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const FundRMF = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [errorRMF, setErrorRMF] = useState(false);
  const balanceData = useBalanceStore((state) => state.balanceData);
  // console.log("balanceData: ",balanceData?.sum_salary_peryear)

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_provident_rmf_value: output });

    setForm({
      ...form,
      fund_provident_rmf_value: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const displaymaxvalue = useMemo(
    () => checkSSF(form.tax_income, form?.deduction_expenses),
    [form?.tax_income, form?.deduction_expenses]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_provident_rmf_value, form?.tax_income * 0.3),
    [form.fund_provident_rmf_value, form?.tax_income]
  );

  const getPercentBar = useMemo(
    () => getPercent(form.fund_provident_rmf_value, form?.tax_income * 0.3),
    [form?.fund_provident_rmf_value, form?.tax_income]
  );

  const error = useMemo(
    () =>
      checkError(form.tax_income, 0.3, form?.fund_provident_rmf_value, 200000),
    [form.tax_income, 0.3, form?.fund_provident_rmf_value, 200000]
  );

  const checkSum = useMemo(
    () =>
      calSum5Value(
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value
      ),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
    ]
  );

  const checkErrorRMF = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="ค่าซื้อหน่วยลงทุนใน"
          title2="กองทุนรวมเพื่อการเลี้ยงชีพ (RMF) "
          remain={displayRemain}
          value={form.fund_provident_rmf_value}
          maxvalue={form?.tax_income * 0.3}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_provident_rmf_value > form?.tax_income * 0.3}
          errortext={`200,000`}
          etc
          textetc={`ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (RMF) และไม่เกินร้อยละ 30 ของรายได้พึงประเมิน`}
          textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000 บาท`}
          infoPopUpHeader={
            "หลักเกณฑ์การยกเว้นเงินได้สำหรับกองทุนรวมเพื่อการเลี้ยงชีพ (RMF)"
          }
          infoPopUpText={
            <div>
              <div>
                {" "}
                หลักเกณฑ์การยกเว้นเงินได้สำหรับกองทุนรวมเพื่อการเลี้ยงชีพ (RMF)
              </div>
              <div>
                - ให้หักเป็นรายการยกเว้นในอัตราไม่เกินร้อยละ 30 ของเงินได้
                แต่ไม่เกิน 500,000 บาท
              </div>
              <div>
                - กรณีซื้อหน่วยลงทุนตั้งแต่วันที่ 1 มกราคม พ.ศ. 2565 เป็นต้นไป
                ต้องแจ้งความประสงค์ที่จะใช้สิทธิยกเว้นภาษีเงินได้ต่อบริษัทหลักทรัพย์จัดการกองทุนรวมที่ตนได้ซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ
              </div>
              <div>
                - กองทุนรวมเพื่อการเลี้ยงชีพ (RMF) ที่ได้รับยกเว้นนั้น
                เมื่อรวมกับ
              </div>
              <div>(1) เงินสะสมกองทุนสำรองเลี้ยงชีพ</div>
              <div>(2) กบข.</div>
              <div>(3) เงินสะสมกองทุนสงเคราะห์ครูโรงเรียนเอกชน</div>
              <div>(4) กองทุนการออมแห่งชาติ</div>
              <div>(5) เบี้ยประกันแบบบำนาญ</div>
              <div>(6) ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)</div>
              <div>แล้วต้องไม่เกิน 500,000 บาท</div>
              <div>
                - ต้องซื้อไม่น้อยกว่าปีละ 1 ครั้ง
                และไม่ระงับการซื้อเป็นเวลาเกินกว่า 1 ปีติดต่อกัน
              </div>
              <div>
                - ต้องถือไว้ไม่น้อยกว่า 5 ปีนับตั้งแต่วันซื้อหน่วยลงทุนครั้งแรก
                หากผิดเงื่อนไขต้องยื่นแบบเพิ่มเติมและรับผิดเงินเพิ่ม หากยื่นแบบฯ
                ภายในเดือนมีนาคมของปีถัดจากปีที่ผิดเงื่อนไขไม่ต้องเสียเงินเพิ่ม
              </div>
              <div>
                - ให้เป็นไปตามหลักเกณฑ์ วิธีการและเงื่อนไข
                ตามประกาศอธิบดีกรมสรรพากร เกี่ยวกับภาษีเงินได้ (ฉบับที่ 414)
              </div>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            **ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_provident_rmf_value =
                newForm.fund_provident_rmf_value_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (
              form?.fund_provident_rmf_value >
              balanceData?.sum_salary_peryear * 0.3
            ) {
              return;
            } else {
              const { amouth } = checkErrorRMF(form);
              if (amouth) {
                return setErrorRMF(true);
              } else {
                setForm({
                  ...form,
                  fund_provident_rmf_value: Number(
                    form.fund_provident_rmf_value
                  ),
                  fund_provident_rmf_value_prev: Number(
                    form.fund_provident_rmf_value
                  ),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }
            }
          }}
        />
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-36 -z-10`}
      />
    </Drawer>
  );
};

export const FundSSF = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const [error, setError] = useState(false);

  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 200000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_provident_ssf_value: output });

    setForm({
      ...form,
      fund_provident_ssf_value: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const displaymaxvalue = useMemo(
    () => checkSSF(form.tax_income, form?.deduction_expenses),
    [form?.tax_income, form?.deduction_expenses]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.fund_provident_ssf_value, 200000),
    [form.fund_provident_ssf_value, 200000]
  );

  const getPercentBar = useMemo(
    () => getPercent(form.fund_provident_ssf_value, 200000),
    [form?.fund_provident_ssf_value, 200000]
  );
  const checkSum = useMemo(
    () =>
      calSum5Value(
        form?.insurance_annuity,
        form?.fund_provident,
        form?.fund_nsf,
        form?.fund_provident_rmf_value,
        form?.fund_provident_ssf_value
      ),
    [
      form?.insurance_annuity,
      form?.fund_provident,
      form?.fund_nsf,
      form?.fund_provident_rmf_value,
      form?.fund_provident_ssf_value,
    ]
  );

  const checkError = () => {
    const amouth = checkSum > 500000;
    return { amouth };
  };

  // const error = useMemo(
  //   () =>
  //     checkError(form.tax_income, 0.15, form?.fund_provident_ssf_value, 200000),
  //   [form.tax_income, 0.15, form?.fund_provident_ssf_value, 200000]
  // );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="ค่าซื้อหน่วยลงทุนในกองทุน"
          title2={`รวมเพื่อการออม (SSF)`}
          remain={displayRemain}
          value={form.fund_provident_ssf_value}
          maxvalue={200000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_provident_ssf_value > 200000 ? true : false}
          errortext={`200,000`}
          etc
          textetc={`ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF) ห้ามเกิน 200,000 บาท และไม่เกินร้อยละ 15 ของรายได้พึงประเมิน`}
          textetc2={`กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ, ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF), กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000 บาท`}
          infoPopUpHeader={"หลักเกณฑ์การหักลดหย่อน SSF"}
          infoPopUpText={
            <div>
              <div>
                "ให้หักเป็นรายการยกเว้นในอัตราไม่เกินร้อยละ 30 ของเงินได้
                แต่ไม่เกิน 200,000 บาท"
              </div>
              <div>
                ซื้อหน่วยลงทุนตั้งแต่วันที่ 1 มกราคม พ.ศ. 2563 ถึงวันที่ 31
                ธันวาคม พ.ศ. 2567
              </div>
              <div>
                ต้องไม่ใช่ห้างหุ้นส่วนสามัญ
                หรือคณะบุคคลที่มิใช่นิติบุคคลและกองมรดกที่ยังไม่ได้แบ่ง
              </div>
              <div>
                ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการออม (SSF)
                ที่ได้รับยกเว้นนั้น เมื่อรวมกับ
              </div>
              <div>(1) เงินสะสมกองทุนสำรองเลี้ยงชีพ</div>
              <div>(2) กบข.</div>
              <div>(3) เงินสะสมกองทุนสงเคราะห์ครูโรงเรียนเอกชน</div>
              <div>(4) กองทุนการออมแห่งชาติ</div>
              <div>(5) เบี้ยประกันแบบบำนาญ</div>
              <div>(6) กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)</div>
              <div>
                แล้วต้องไม่เกิน 500,000 บาท (เมื่อเกินจำนวน ควรพิจารณาลดค่าซื้อ
                SSF เป็นรายการสุดท้าย เนื่องจากหาก
              </div>
              <div>
                ไม่ได้ใช้สิทธิลดหย่อน จะไม่สามารถใช้สิทธิยกเว้นเงินได้ขณะขายคืน)
              </div>
              <div>
                ต้องถือไว้ไม่น้อยกว่า 10 ปีนับตั้งแต่วันซื้อหน่วยลงทุนครั้งแรก
                หากผิดเงื่อนไขต้องยื่นแบบเพิ่มเติมและรับผิดเงินเพิ่ม
                เว้นแต่กรณีที่ผู้มีเงินได้ไถ่ถอนหน่วยลงทุนในกองทุนรวมเพื่อการออมนั้นเพราะเหตุทุพพลภาพหรือตาย
              </div>
              <div>
                ให้เป็นไปตามหลักเกณฑ์ วิธีการและเงื่อนไข
                ตามประกาศอธิบดีกรมสรรพากร เกี่ยวกับภาษีเงินได้ (ฉบับที่ 369)
              </div>
            </div>
          }
        />
        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
          <span className="text-mainblue font-semibold ">
            **ตอนนี้ใช้ไปแล้ว {numeral(checkSum).format("0,0")} บาท
            {checkSum > 500000 && (
              <div className="text-red-500 font-semibold text-center mt-1">
                เกินสิทธิ
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_provident_ssf_value =
                newForm.fund_provident_ssf_value_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.fund_provident_ssf_value > 200000) {
              return;
            } else {
              const { amouth } = checkError(form);
              if (amouth) {
                return setError(true);
              } else {
                setForm({
                  ...form,
                  fund_provident_ssf_value: Number(
                    form.fund_provident_ssf_value
                  ),
                  fund_provident_ssf_value_prev: Number(
                    form.fund_provident_ssf_value
                  ),
                });
                setOpenDrawer(true);
                setOpenInfo(false);
              }
            }
          }}
        />
      </div>
      <img
        src="/images/bgblue.png"
        className={`w-full absolute -bottom-36 -z-10`}
      />
    </Drawer>
  );
};

export const HomeLoan = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 100000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, home_loan: output });

    setForm({
      ...form,
      home_loan: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.home_loan, 100000),
    [form?.home_loan, 100000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.home_loan, 100000),
    [form?.home_loan, 100000]
  );
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="ดอกเบี้ยเงินกู้ยืมเพื่อซื้อ"
          title2="เช่าหรือสร้างอาคารอยู่อาศัย"
          remain={displayRemain}
          value={form.home_loan}
          maxvalue={100000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-5.png"
          error={form?.home_loan > 100000 ? true : false}
          errortext={100000}
          infoPopUpHeader="หลักเกณฑ์การลดหย่อนดอกเบี้ยเงินกู้ยืมเพื่อที่อยู่อาศัย"
          infoPopUpText={
            <div>
              <div>
                {" "}
                - ได้รับลดหย่อนและยกเว้นภาษีตามจำนวนที่จ่ายจริงแต่ไม่เกิน
                100,000. บาท
              </div>
              <div>
                - เป็นดอกเบี้ยเงินกู้ยืมตามสัญญากู้ยืมเงินเพื่อซื้อ
                เช่าซื้ออาคาร อาคารพร้อมที่ดิน หรือห้องชุด
                หรือสร้างอาคารใช้อยู่อาศัย บนที่ดินของตน
              </div>
              <div>- ต้องจำนองอาคารที่ซื้อหรือสร้างนั้นเป็นประกันการกู้ยืม</div>
              <div>
                - หากผู้มีเงินได้มีอาคารหรือห้องชุดหลายแห่ง
                ให้ใช้สิทธิได้ทุกแห่ง รวมกันตามจำนวนที่จ่ายจริงแต่ไม่เกิน
                100,000. บาท
              </div>
              <div>
                - หลายคนร่วมกันกู้ยืม (กู้ร่วม)
                ให้เฉลี่ยดอกเบี้ยเงินกู้ยืมนั้นตามจำนวน ผู้กู้ร่วม
                โดยใช้จำนวนดอกเบี้ยฯ ที่จ่ายจริง ซึ่งไม่เกิน 100,000. บาท
              </div>
              <div>
                - กรณีสามีภริยาร่วมกันกู้โดยสามีหรือภริยามีเงินได้ฝ่ายเดียว
                ให้ผู้มีเงินได้ ใช้สิทธิเต็มตามจำนวนที่จ่ายจริง แต่ไม่เกิน
                100,000. บาท หากคู่สมรส ไม่มีเงินได้ แต่มีชื่อเป็นผู้กู้
                หักลดหย่อนดอกเบี้ยเงินกู้ยืมไม่ได้
              </div>
              <div>
                - กรณีกู้เพิ่มเติมจากสัญญาเดิมไม่ได้รับสิทธิให้นำมาหักลดหย่อน
              </div>
              <div>- ต้องมีหนังสือรับรองตามแบบที่อธิบดีกำหนด</div>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.home_loan = newForm.home_loan_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.home_loan > 100000) {
              return;
            } else {
              setForm({
                ...form,
                home_loan: Number(form.home_loan),
                home_loan_prev: Number(form.home_loan),
              });
            }
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const FundSocial = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 9000) return;
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, fund_social_security: output });

    setForm({
      ...form,
      fund_social_security: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form?.fund_social_security, 9000),
    [form?.fund_social_security, 9000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form?.fund_social_security, 9000),
    [form?.fund_social_security, 9000]
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={5}
          title="เงินสมทบกองทุนประกันสังคม"
          remain={displayRemain}
          value={form?.fund_social_security}
          maxvalue={9000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.fund_social_security > 9000 ? true : false}
          errortext={9000}
          infoPopUpHeader="เงินสมทบกองทุนประกันสังคม"
          infoPopUpText={
            <div>
              <div>
                สำหรับปีภาษี 2566 สามารถหักลดหย่อนตามกฎหมายว่าด้วยการประกันสังคม
              </div>
              <div>- มาตรา 33 ได้ตามจำนวนที่จ่ายจริง แต่ไม่เกิน 9,000 บาท</div>
              <div>- มาตรา 39 ได้ตามจำนวนที่จ่ายจริง แต่ไม่เกิน 5,184 บาท</div>
              <div>- มาตรา 40</div>
              <div>ทางเลือกที่ 1 ได้ตามจำนวนที่จ่ายจริง แต่ไม่เกิน 840 บาท</div>
              <div>
                ทางเลือกที่ 2 ได้ตามจำนวนที่จ่ายจริง แต่ไม่เกิน 1,200 บาท
              </div>
              <div>
                ทางเลือกที่ 3 ได้ตามจำนวนที่จ่ายจริง แต่ไม่เกิน 3,600 บาท
              </div>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.fund_social_security = newForm?.fund_social_security_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.fund_social_security > 9000) {
              return;
            } else {
              setForm({
                ...form,
                fund_social_security: Number(form?.fund_social_security),
                fund_social_security_prev: Number(form?.fund_social_security),
              });
              setOpenDrawer(true);
              setOpenInfo(false);
            }
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Prenatal = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (e.target.value > 60000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, prenatal_care_cost: output });

    setForm({
      ...form,
      prenatal_care_cost: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.prenatal_care_cost, 60000),
    [form?.prenatal_care_cost, 60000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.prenatal_care_cost, 60000),
    [form?.prenatal_care_cost, 60000]
  );
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={6}
          title="ค่าฝากครรภ์และค่าคลอดบุตร"
          remain={displayRemain}
          value={form.prenatal_care_cost}
          maxvalue={60000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-4.png"
          error={form?.prenatal_care_cost > 60000 ? true : false}
          errortext={`60,000`}
          infoPopUpHeader="ค่าฝากครรภ์และค่าคลอดบุตร"
          infoPopUpText={
            <div>
              <div>หลักเกณฑ์การหักลดหย่อน</div>
              <div>
                - เงินที่ผู้มีเงินได้หรือคู่สมรสได้จ่ายค่าฝากครรภ์และค่าคลอดบุตร
                เช่น ค่าตรวจ และรับฝากครรภ์ ค่าบำบัดทางการแพทย์ ค่ายา ค่าทำคลอด
                เป็นต้น
              </div>
              <div>- จ่ายให้แก่สถานพยาบาลของทางราชการ หรือของเอกชน</div>
              <div>
                - ตามจำนวนที่จ่ายจริงในแต่ละปีภาษี สำหรับการตั้งครรภ์แต่ละคราว
                ไม่เกิน 60,000 บาท
              </div>
              <div>- ใช้บังคับตั้งแต่ วันที่ 1 มกราคม 2561 เป็นต้นไป</div>
              <div>
                - ต้องมีเอกสารมาแสดง ได้แก่
                ใบรับรองแพทย์ที่แสดงความเห็นว่ามีภาวะตั้งครรภ์
                และใบเสร็จรับเงินหรือหลักฐานอื่นใดที่ได้จ่ายให้แก่สถานพยาบาล
              </div>
              <div>
                - ต้องรวมกับสิทธิการเบิกค่าฝากครรภ์และค่าคลอดบุตร
                ซึ่งเป็นสวัสดิการจากภาครัฐหรือภาคเอกชน
                สำหรับการตั้งครรภ์แต่ละคราวแล้ว ไม่เกิน 60,000 บาท
              </div>
              <div>
                - ให้เป็นไปตามหลักเกณฑ์ วิธีการและเงื่อนไข
                ตามประกาศอธิบดีกรมสรรพากร เกี่ยวกับภาษีเงินได้ (ฉบับที่ 331)
              </div>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.prenatal_care_cost = newForm.prenatal_care_cost_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.prenatal_care_cost > 60000) {
              return;
            } else {
              setForm({
                ...form,
                prenatal_care_cost: Number(form.prenatal_care_cost),
                prenatal_care_cost_prev: Number(form.prenatal_care_cost),
              });
            }
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Political = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, political_donate: output });

    setForm({
      ...form,
      political_donate: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.political_donate, 10000),
    [form?.political_donate, 10000]
  );

  const displayRemain = useMemo(
    () => getcalPaid(form.political_donate, 10000),
    [form?.political_donate, 10000]
  );
  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={6}
          title="เงินที่บริจาคแก่พรรคการเมือง"
          remain={displayRemain}
          value={form.political_donate}
          maxvalue={10000}
          valueprogress={getPercentBar}
          onChange={onInsuranceChange}
          img="/images/health-insurance-3.png"
          error={form?.political_donate > 10000 ? true : false}
          errortext={`10,000`}
          infoPopUpHeader="หลักเกณฑ์การหักลดหย่อน"
          infoPopUpText={
            <div>
              <div>
                - เงิน ทรัพย์สิน หรือประโยชน์อื่นใด
                ที่ให้เพื่อสนับสนุนการจัดกิจกรรมระดมทุนของพรรคการเมือง
              </div>
              <div>
                - ผู้มีเงินได้ที่มีสิทธิหักลดหย่อนต้องเป็นบุคคลธรรมดา
                มีสัญชาติไทย
              </div>
              <div>- ตามจำนวนที่จ่ายจริงแต่ไม่เกิน 10,000 บาท</div>
              <div>- ใช้บังคับตั้งแต่ วันที่ 1 มกราคม 2561 เป็นต้นไป</div>
              <div>
                - ต้องมีเอกสารมาแสดง
                คือใบเสร็จรับเงินหรือหลักฐานอื่นใดที่พิสูจน์ได้ถึงการบริจาคดังกล่าว
              </div>
              <div>
                - ให้เป็นไปตามหลักเกณฑ์ วิธีการและเงื่อนไข
                ตามประกาศอธิบดีกรมสรรพากร เกี่ยวกับภาษีเงินได้ (ฉบับที่ 357)
              </div>
            </div>
          }
        />
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.political_donate = newForm.political_donate_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.political_donate > 60000) {
              return;
            } else {
              setForm({
                ...form,
                political_donate: Number(form.political_donate),
                political_donate_prev: Number(form.political_donate),
              });
            }
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const Education = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const displayMaxDeductSum = useMemo(
    () =>
      getcalcRemainEducaid(
        getValueremian(form, "deduct_summary_except_education_donate")
      ),
    [getValueremian()]
  );

  const checkDisplayMaxDeductSum = useMemo(
    () => checkMinusMaxValue(displayMaxDeductSum),
    [displayMaxDeductSum]
  );

  const displayRemain = displayMaxDeductSum - form?.education_donate;

  const onEducationChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""));

    let rawOutput = output;
    let finalOutput = output * 2;

    const { result, sumResult, sumResultExceptOtherDonate } = generateSummary({
      ...form,
      education_donate: isNaN(output) ? 60000 : finalOutput,
    });

    if (isNaN(output)) {
      return setForm({
        ...form,
        education_donate: "",
        raw_education_donate: "",
        deduct_summary_except_other_donate: sumResultExceptOtherDonate - 60000,
      });
    }

    setForm({
      ...form,
      education_donate: finalOutput,
      raw_education_donate: rawOutput,
      deduct_summary: sumResult,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form?.education_donate, checkDisplayMaxDeductSum),
    [form?.education_donate, checkDisplayMaxDeductSum]
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="เงินบริจาคสนับสนุนการศึกษา"
          title2={`/สถานพยาบาล/สภากาชาดไทย/อื่น ๆ`}
          remain={displayRemain}
          value={form?.education_donate}
          valueDisplay={form?.raw_education_donate}
          maxvalue={checkDisplayMaxDeductSum}
          valueprogress={getPercentBar}
          onChange={onEducationChange}
          img="/images/health-insurance-3.png"
          error={
            form?.education_donate > checkDisplayMaxDeductSum ? true : false
          }
          errortext={
            checkDisplayMaxDeductSum === 0 ? false : checkDisplayMaxDeductSum
          }
          infoPopUpHeader="หลักเกณฑ์การหักบริจาคเงินสนับสนุนการศึกษา/สถานพยาบาล/อื่นๆ"
          infoPopUpText={
            <div>
              <div>
                {" "}
                - หักได้ 2 เท่าของจำนวนเงินที่จ่ายไปจริง แต่ไม่เกินร้อยละ 10
                ของเงินได้หลังหักค่าใช้จ่ายและค่าลดหย่อน
              </div>
              <div>
                - เงินที่บริจาคให้แก่สถานศึกษาของทางราชการ โรงเรียนเอกชน
                สถาบันอุดมศึกษาเอกชน
              </div>
              <div>
                -
                จ่ายเป็นค่าใช้จ่ายเพื่อสนับสนุนการศึกษาสำหรับโครงการที่กระทรวงศึกษาธิการให้ความเห็นชอบ
                หรือ
              </div>
              <div>
                - จ่ายเป็นค่าใช้จ่ายเพื่อการจัดหาหรือจัดสร้างอาคาร
                อาคารพร้อมที่ดิน ที่ดิน วัสดุอุปกรณ์เพื่อการศึกษา แบบเรียน
                หนังสือทางวิชาการ ตำรา ฯลฯ จัดหาครู อาจารย์ ฯลฯ
                หรือเป็นทุนการศึกษา การค้นคว้า การวิจัย ฯลฯ
              </div>
              <div>
                - ต้องมีหลักฐานจากสถานศึกษา
                และเอกสารหลักฐานที่ได้รับต้องระบุว่าเป็นการสนับสนุนการศึกษาเพื่อ..............
              </div>
              <div>- เงินที่ได้บริจาคให้แก่สถานพยาบาลของทางราชการ</div>
              <div>
                - เงินที่ได้บริจาคให้แก่กองทุนต่างๆ เช่น กองทุนยุติธรรม
                กองทุนพัฒนาสื่อปลอดภัยและสร้างสรรค์
              </div>
              <div>- การบริจาคต้องเป็นการบริจาคเงินสดเท่านั้น</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: `*** ค้นหารายชื่อสถานศึกษาจาก www.rd.go.th > บริการข้อมูล > รายชื่อสถานศึกษาที่สามารถหักลดหย่อนเงินบริจาคให้ 1 ได้ 2 ***`,
                }}
              ></div>
            </div>
          }
        />
        <div className="flex justify-center text-center text-mainblue mt-2 font-medium">
          {" "}
          สิทธิ์ที่ได้รับสองเท่า{" "}
          {numeral?.(form?.education_donate)?.format("0,0")} บาท
        </div>

        <div className={STYLE_BOX_DONATE}>
          {checkDisplayMaxDeductSum === 0 ? (
            <div>
              ขอชื่นชมที่ท่านมีจิตกุศลช่วยเหลือสังคม
              <div>แต่ท่านไม่สามารถใช้สิทธิลดหย่อนหมวดนี้ได้</div>
            </div>
          ) : (
            "ขอชื่นชมที่ท่านมีจิตกุศลช่วยเหลือสังคม"
          )}
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.education_donate = newForm.education_donate_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.education_donate > checkDisplayMaxDeductSum) {
              return;
            } else {
              setForm({
                ...form,
                education_donate: Number(form?.education_donate),
                education_donate_prev: Number(form?.education_donate),
              });
            }
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} alt="" />
    </Drawer>
  );
};

export const OtherDonate = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  // const getValueremian = () => {
  //   let income = form?.tax_income;
  //   let deductAll = form?.deduct_summary_except_other_donate;
  //   let results = income - 100000 - deductAll;
  //   // console.log("results", results);
  //   return results;
  // };

  const displayMaxDeductSum = useMemo(
    () =>
      getcalcRemainEducaid(
        getValueremian(form, "deduct_summary_except_other_donate")
      ),
    [getValueremian()]
  );

  const checkDisplayMaxDeductSum = useMemo(
    () => checkMinusMaxValue(displayMaxDeductSum),
    [displayMaxDeductSum]
  );

  const displayRemain = displayMaxDeductSum - form?.other_donate;

  const onChangeOtherDonate = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;

    const { result, sumResult, sumResultExceptEducationDonate } =
      generateSummary({ ...form, other_donate: output });

    setForm({
      ...form,
      other_donate: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
    });
  };

  const getPercentBar = useMemo(
    () => getPercent(form.other_donate, checkDisplayMaxDeductSum),
    [form?.other_donate, checkDisplayMaxDeductSum]
  );

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <PanelTax
          maxLength={7}
          title="เงินบริจาคอื่น ๆ"
          remain={displayRemain}
          value={form?.other_donate}
          maxvalue={checkDisplayMaxDeductSum}
          valueprogress={getPercentBar}
          onChange={onChangeOtherDonate}
          img="/images/health-insurance-3.png"
          error={form?.other_donate > checkDisplayMaxDeductSum ? true : false}
          errortext={
            checkDisplayMaxDeductSum === 0 ? false : checkDisplayMaxDeductSum
          }
          infoPopUpHeader="หลักเกณฑ์การหักลดหย่อนเงินบริจาค"
          infoPopUpText={
            <div>
              <div>
                {" "}
                - หักได้ตามที่จ่ายจริง แต่ไม่เกินร้อยละ 10
                ของเงินได้หลังหักค่าใช้จ่าย ค่าลดหย่อน
                และเงินบริจาคสนับสนุนการศึกษา
              </div>
              <div>
                - บริจาคเงินให้แก่วัดวาอาราม สภากาชาดไทย สถานพยาบาลและ
                สถานศึกษาของทางราชการ หรือองค์การของรัฐบาล สถานศึกษาเอกชน
                สถานสาธารณกุศล และกองทุนสวัสดิการภายในส่วนราชการ ฯลฯ
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    "*** ค้นหารายชื่อ มูลนิธิ สมาคม สถานสาธารณกุศล / รายชื่อสถานสงเคราะห์ สถานพักฟื้นบำบัดและฟื้นฟูเด็ก คนชรา คนพิการ ที่สามารถหักค่าลดหย่อนเงินบริจาคได้จาก www.rd.go.th > บริการข้อมูล > ข้อมูลสำหรับผู้เสียภาษีเงินได้บุคคลธรรมดา ***",
                }}
              ></div>
            </div>
          }
        />
        <div className={STYLE_BOX_DONATE}>
          {checkDisplayMaxDeductSum === 0 ? (
            <div>
              ขอชื่นชมที่ท่านมีจิตกุศลช่วยเหลือสังคม
              <div>แต่ท่านไม่สามารถใช้สิทธิลดหย่อนหมวดนี้ได้</div>
            </div>
          ) : (
            "ขอชื่นชมที่ท่านมีจิตกุศลช่วยเหลือสังคม"
          )}
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.other_donate = newForm.other_donate_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            if (form?.other_donate > checkDisplayMaxDeductSum) {
              return;
            } else {
              setForm({
                ...form,
                other_donate: Number(form.other_donate),
                other_donate_prev: Number(form.other_donate),
              });
            }
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} alt="" />
    </Drawer>
  );
};

export const Special = ({
  openInfo,
  setOpenInfo,
  setForm,
  form,
  setOpenDrawer,
}) => {
  const onInsuranceChange = (e) => {
    const value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : null;
    if (isNaN(output)) return;
    // if (isNaN(e.target.value)) return;
    // if (Number(e.target.value) > 1000000) return;
    const {
      result,
      sumResult,
      sumResultExceptEducationDonate,
      sumResultExceptOtherDonate,
    } = generateSummary({ ...form, special_discount_gov: output });

    setForm({
      ...form,
      special_discount_gov: output,
      deduct_summary: sumResult,
      deduct_summary_except_education_donate: sumResultExceptEducationDonate,
      deduct_summary_except_other_donate: sumResultExceptOtherDonate,
    });
  };

  const onInsuranceChangeName = (e) => {
    setForm({ ...form, special_discount_gov_name: e.target.value });
  };
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  return (
    <Drawer
      placement="bottom"
      open={openInfo}
      className=" !max-h-screen overflow-y-scroll"
    >
      <PopupMain
        img={null}
        onClick={() => {
          setOpenInfoPopUp(false);
        }}
        open={openInfoPopUp}
        text={
          <div className=" flex flex-col items-start w-full px-[20px] text-left text-sm ">
            <div className="font-medium">
              ค่าซื้อสินค้าหรือบริการ ช้อปดีมีคืน (ตั้งแต่วันที่ 1 ม.ค. 2566 -
              15 ก.พ. 2566)
            </div>
            <div className="font-normal">
              <div>
                หลักเกณฑ์การยกเว้นเงินได้สำหรับค่าซื้อสินค้าหรือบริการ
                ช้อปดีมีคืน
              </div>
              <div>
                - ค่าซื้อสินค้าหรือค่าบริการ
                ที่จ่ายให้แก่ผู้ประกอบการหรือผู้ประกอบการจดทะเบียนมูลค่าเพิ่มแล้วแต่กรณี
                สำหรับการซื้อสินค้าหรือบริการในราชอาณาจักร
                เป็นเงินได้พึงประเมินที่ได้รับยกเว้นไม่ต้องรวมคำนวณ
                เพื่อเสียภาษีเงินได้บุคคลธรรมดา ทั้งนี้ ไม่ว่าใบกำกับภาษี
                หรือใบรับจะได้จัดทำในรูปแบบกระดาษหรือโดยวิธีการทางอิเล็กทรอนิกส์
              </div>
              <div>
                - ตั้งแต่วันที่ 1 มกราคม พ.ศ. 2566 ถึงวันที่ 15 กุมภาพันธ์ พ.ศ.
                2566
              </div>
              <div>- ตามจำนวนที่จ่ายจริงแต่ไม่เกิน 40,000 บาท</div>
              <div>
                - ใบกำกับภาษีรูปแบบอิเล็กทรอนิกส์และใบกำกับรูปแบบกระดาษ
                รวมกันไม่เกิน 40,000 บาท
              </div>
              <div>- ใบกำกับรูปแบบกระดาษอย่างเดียว ไม่เกิน 30,000 บาท</div>
              <div>
                - ผู้มีเงินได้ที่ได้รับสิทธิยกเว้นภาษีเงินได้
                ต้องไม่เป็นห้างหุ้นส่วนสามัญหรือคณะบุคคลที่มิใช่นิติบุคคล
              </div>
              <div>
                - ให้เป็นไปตามหลักเกณฑ์ และเงื่อนไข ตามกฎกระทรวง ฉบับที่ 386
                (พ.ศ. 2565) และประกาศอธิบดีกรมสรรพากร เกี่ยวกับภาษีเงินได้ (
                ฉบับที่ 431)
              </div>
            </div>
          </div>
        }
      />
      <div className={STYLE_MAX_W_DRAWER_TAX}>
        <div className="mt-16">
          <div className={`${STYLE_TITLE_DRAWER} flex justify-center `}>
            สิทธิลดหย่อนพิเศษ{" "}
            <ICON_INFO
              onClick={() => {
                console.log("click");
                setOpenInfoPopUp(!openInfoPopUp);
              }}
              className={` w-4 h-4 text-maingray mt-1`}
            />
          </div>
          <div className=" text-md text-center text-mainblue font-semibold mt-1">
            จากนโยบายรัฐบาล
          </div>
          <div className="  pt-6">
            <div className={`${STYLE_CARD_BG} p-[10px] mb-5`}>
              <div className="flex justify-between gap-4 w-full ">
                <div className=" text-sm font-medium pl-[14px] text-black">
                  ระบุชื่อโครงการ
                  <div className=" mt-2">
                    <input
                      placeholder="กรุณาระบุชื่อ"
                      style={{
                        borderRadius: "6px",
                      }}
                      className={`${STYLE_INPUT_INSET} w-full`}
                      value={form.special_discount_gov_name}
                      onChange={onInsuranceChangeName}
                    />
                  </div>
                </div>
                <img
                  src={`images/health-insurance-3.png`}
                  className={STYLE_IMG_DRAWER}
                />
              </div>
            </div>
            <CardMain
              title="เบี้ยประกันที่จ่ายตลอดทั้งปี"
              panel={
                <div className="">
                  <div className=" flex justify-center items-center gap-2.5 mt-5">
                    <div>จำนวนเงิน</div>

                    <NumbericValidateRed
                      // disabled={disabled}
                      maxLength={7}
                      value={form.special_discount_gov}
                      placeholder="0"
                      onChange={onInsuranceChange}
                      thousandSeparator=","
                      inputMode="decimal"
                      pattern="[0-9],*"
                      className={STYLE_INPUT_INSET}
                    />
                    <div>บาท</div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className={`${STYLE_PARENT_BUTTON_DRAWER} `}>
        <MTButton
          text="ยกเลิก"
          color=" border border-maincyan "
          colortext="text-maincyan group-hover:text-white"
          hovercolor="hover:bg-maincyan group "
          onClick={(index, e) => {
            setForm((prev) => {
              const newForm = { ...prev };
              newForm.special_discount_gov = newForm.special_discount_gov_prev;
              return newForm;
            });
            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
        <MTButton
          text="ยืนยัน"
          onClick={() => {
            setForm({
              ...form,
              special_discount_gov_name: form.special_discount_gov_name,
              special_discount_gov: Number(form.special_discount_gov),
              special_discount_gov_prev: Number(form.special_discount_gov),
            });

            setOpenDrawer(true);
            setOpenInfo(false);
          }}
        />
      </div>
      <img src="/images/bgblue.png" className={STYLE_BG_BLUE_DRAWER} />
    </Drawer>
  );
};

export const DrawerMain = ({
  openDrawer,
  setOpen,
  openInfo,
  setOpenInfo,
  form,
  setForm,
  setOpenDrawer,
}) => {
  return (
    <div className="">
      <DeductionDrawerMain
        open={openDrawer}
        setOpen={setOpen}
        setOpenDrawer={setOpenDrawer}
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        form={form}
        setForm={setForm}
      />
    </div>
  );
};

const DeductionDrawerMain = ({
  open,
  setOpen,
  form,
  setForm,
  setOpenInfo,
  setOpenDrawer,
  setIshasChild,
  index,
}) => {
  const [loading, setLoading] = useState();

  const [errorEducationDonate, setErrorEducationDonate] = useState(false);
  const [errorOtherDonate, setErrorOtherDonate] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  // Max Education Donate
  const maxDeductEducationDonateSum = useMemo(
    () =>
      getcalcRemainEducaid(
        getValueremian(form, "deduct_summary_except_education_donate")
      ),
    [getValueremian()]
  );

  // Max Other Donate
  const maxDeductOtherDonateSum = useMemo(
    () =>
      getcalcRemainEducaid(
        getValueremian(form, "deduct_summary_except_other_donate")
      ),
    [getValueremian()]
  );

  const checkMaxEducationDonate = useMemo(
    () => checkMinusMaxValue(maxDeductEducationDonateSum),
    [maxDeductEducationDonateSum]
  );

  const checkMaxOtherDonate = useMemo(
    () => checkMinusMaxValue(maxDeductOtherDonateSum),
    [maxDeductOtherDonateSum]
  );

  useEffect(() => {
    // Check Education Donate
    if (form?.education_donate > checkMaxEducationDonate) {
      setErrorEducationDonate(true);
    } else {
      setErrorEducationDonate(false);
    }
    // Check Other Donate
    if (form?.other_donate > checkMaxOtherDonate) {
      setErrorOtherDonate(true);
    } else {
      setErrorOtherDonate(false);
    }
  }, [checkMaxEducationDonate, checkMaxOtherDonate]);

  useEffect(() => {
    setOpenAlert(errorEducationDonate || errorOtherDonate);
  }, [errorEducationDonate, errorOtherDonate]);

  const TitleStyle = ({
    title,
    value,
    onClick,
    error = false,
    errorValue = "*ค่าเกิน",
    disabled = false,
  }) => {
    return (
      <div
        onClick={disabled ? () => {} : onClick}
        className=" border-b border-b-gray55 cursor-pointer hover:bg-secoundblue/20 duration-200"
      >
        <div className=" grid grid-cols-5 justify-between px-6 py-2.5">
          <div className={`text-black text-md font-medium col-span-3`}>
            {title}{" "}
            {error && (
              <span className="text-red-500 text-sm pl-1">{errorValue}</span>
            )}
          </div>
          {value ? (
            <div
              className={` ${
                error ? "text-red-500" : "text-maincyan"
              } text-md font-medium text-right col-span-2 flex-none `}
            >
              {value}
            </div>
          ) : (
            <div className="text-maincyan text-md font-medium text-right col-span-2 flex-none">
              0 บาท
            </div>
          )}
        </div>
      </div>
    );
  };
  const { deduction, setDeduction } = useTaxPlanContext();

  const size = useWindowSize();

  return (
    <Drawer
      size={size.width}
      placement="right"
      open={open}
      className=" overflow-y-scroll "
    >
      <div className={STYLE_MAX_W_DRAWER}>
        <div className="  flex gap-12 mt-10 ml-6 text-center pb-1 w-full">
          <div
            className={`${STYLE_TITLE_DRAWER} flex justify-center items-center w-full pr-6`}
          >
            เพิ่มค่าลดหย่อน
          </div>
        </div>
        <div
          style={{
            backgroundImage: "url(/images/circle.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundSize: "50% 50%",
            backgroundPosition: "0% ",
          }}
          className="min-h-screen "
        >
          <div className="mb-6">
            <HeaderTitle title="คุณและครอบครัว" />
            <TitleStyle
              title={`ผู้มีเงินได้`}
              value={`60,000` + " บาท"}
              onClick={() => {
                // setOpenInfo(1);
                // setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`คู่สมรส`}
              value={numeral(form?.deduct_spouse)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(2);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ลูก`}
              value={numeral(form?.deduct_child)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(3);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`แม่`}
              value={numeral(form?.deduct_mother)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(4);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`พ่อ`}
              value={numeral(form?.deduct_father)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(5);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เลี้ยงดูคนพิการ`}
              errorValue=""
              value={
                form?.deduct_disabilities_people > 0
                  ? "ใช้สิทธิเลี้ยงดูคนทุพพลภาพแล้ว"
                  : numeral(form?.deduct_disable_people)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(6);
                setOpenDrawer(false);
              }}
              error={form?.deduct_disabilities_people > 0 ? true : false}
              disabled={form?.deduct_disabilities_people > 0 ? true : false}
            />
            <TitleStyle
              title={`เลี้ยงดูคนทุพพลภาพ`}
              errorValue=""
              value={
                form?.deduct_disable_people > 0
                  ? "ใช้สิทธิเลี้ยงดูคนพิการแล้ว"
                  : numeral(form?.deduct_disabilities_people)?.format("0,0") +
                    " บาท"
              }
              onClick={() => {
                setOpenInfo(7);
                setOpenDrawer(false);
              }}
              error={form?.deduct_disable_people > 0 ? true : false}
              disabled={form?.deduct_disable_people > 0 ? true : false}
            />
            <TitleStyle
              title={`เบี้ยประกันสุขภาพบิดามารดา`}
              value={
                numeral(form?.deduct_insurance_premiums_parents)?.format(
                  "0,0"
                ) + " บาท"
              }
              onClick={() => {
                setOpenInfo(8);
                setOpenDrawer(false);
              }}
            />
          </div>

          <div className="mb-6">
            <HeaderTitle title="ประกันของคุณ" />
            <TitleStyle
              title={`ประกันชีวิต`}
              value={numeral(form?.insurance_life)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(9);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ประกันสุขภาพ`}
              // value={numeral(form?.insurance_health)?.format("0,0")}
              value={
                form?.insurance_health > 25000
                  ? 0
                  : numeral(form?.insurance_health)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(10);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ประกันชีวิตแบบบำนาญ`}
              value={numeral(form?.insurance_annuity)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(11);
                setOpenDrawer(false);
              }}
            />
          </div>

          <div className="mb-6">
            <HeaderTitle title="หน่วยลงทุน" />
            <TitleStyle
              title={`เงินสะสมกองทุนสำรองเลี้ยงชีพ`}
              value={numeral(form?.fund_provident)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(12);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินสะสมการออมแห่งชาติ`}
              value={numeral(form?.fund_nsf)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(13);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG) `}
              value={numeral(form?.fund_esg)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(14);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ค่าซื้อหน่วยลงทุนในกองทุนรวม
              เพื่อการเลี้ยงชีพ (RMF) `}
              value={
                numeral(form?.fund_provident_rmf_value)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(15);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ค่าซื้อหน่วยลงทุนในกองทุนรวม
              เพื่อการออม (SSF) `}
              value={
                numeral(form?.fund_provident_ssf_value)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(16);
                setOpenDrawer(false);
              }}
            />
          </div>

          <div className="mb-6">
            <HeaderTitle title="อื่น ๆ" />
            <TitleStyle
              title={`ดอกเบี้ยเงินกู้ยืมเพื่อ ซื้อ เช่าซื้อ 
              หรือสร้างอาคารอยู่อาศัย`}
              value={numeral(form?.home_loan)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(17);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินสมทบกองทุนประกันสังคม`}
              value={
                numeral(form?.fund_social_security)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(18);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`ค่าฝากครรภ์และค่าคลอดบุตร `}
              value={numeral(form?.prenatal_care_cost)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(19);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินที่บริจาคแก่พรรคการเมือง `}
              value={numeral(form?.political_donate)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(20);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินบริจาคสนับสนุนการศึกษา / สถานพยาบาล / สภากาชาดไทย / อื่นๆ `}
              error={errorEducationDonate}
              value={numeral(form?.education_donate)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(21);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`เงินบริจาคอื่น ๆ`}
              error={errorOtherDonate}
              value={numeral(form?.other_donate)?.format("0,0") + " บาท"}
              onClick={() => {
                setOpenInfo(22);
                setOpenDrawer(false);
              }}
            />
            <TitleStyle
              title={`สิทธิลดหย่อนพิเศษจาก นโยบายรัฐบาล `}
              value={
                numeral(form?.special_discount_gov)?.format("0,0") + " บาท"
              }
              onClick={() => {
                setOpenInfo(23);
                setOpenDrawer(false);
              }}
            />
          </div>

          <div className={`flex justify-center mb-6 gap-4 `}>
            <MTButton
              text="บันทึก"
              onClick={() => {
                if (errorEducationDonate || errorOtherDonate) {
                  return;
                }

                const { result, sumResult, sumResultExceptEducationDonate } =
                  generateSummary(form);

                setForm((prev) => ({
                  ...prev,
                  deduct_summary: sumResult,
                  deduct_summary_except_education_donate:
                    sumResultExceptEducationDonate,
                }));
                setDeduction(result);
                setOpenDrawer(false);
              }}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default DeductionDrawerMain;
