import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Progress,
} from "@material-tailwind/react";
import {
  STYLE_ACCORDION_BODY_TAX,
  STYLE_ACCORDION_BODY_TAX_2,
  STYLE_ACCORDION_BODY_TAX_3,
  STYLE_ACCORDION_SUBTITLE,
  STYLE_AF_ACCORDION,
  STYLE_BF_ACCORDION,
  STYLE_BORDER_YELLOW_AC,
  STYLE_CARD_BG,
  STYLE_DOK_JAN,
  STYLE_HEAD_AC,
  STYLE_HEAD_GRAY,
  STYLE_INPUT,
  STYLE_INPUT_INSET_ERROR,
  STYLE_INPUT_INSET,
  STYLE_NUMBER_PLAN_BAR,
  STYLE_PARENT_HEAD_AC,
} from "../../../utils/useStyle";
import { CardDeduction2, CardMain } from "../../../components/card/CardMain";
import { MTButton } from "../../../components/button/MTButton";
import React, { useEffect, useMemo, useState } from "react";
import HalfChartMain from "../../../components/chart/HalfChartMain";
import {
  calBadget,
  calInsuarant2,
  calSum5Value,
  calculateTax,
  chechSumResult,
  checkSum,
  getPercent,
  getTaxPaid,
  getcalPaid,
  getcalSum,
} from "../functions";
import { useTaxPlanContext } from "../Tax_Plan.Main";
import numeral from "numeral";
import ViewSummaryTaxPlan from "./VISumTax";
import { ICON_ARROW_UP_DOWN } from "../../../utils/useIcons";
import { NumericFormat } from "react-number-format";
import ErrorMain from "../../../components/error/Error.Main";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import { CardConclusionTax } from "../styles/Card/CardConclusionTax";
import { useTaxStore } from "../../../_store/taxplanStore";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const numberWithoutComma = (value) => {
  let result = parseInt(value?.replace(/,/g, ""), 10);
  return result;
};

export const TaxDrawer = ({ openTaxPlanInfo, setOpenTaxPlanInfo }) => {
  return (
    <InfoDrawer
      head={"วางแผนภาษี"}
      text={
        "  การเตรียมการเพื่อเสียภาษีให้ถูกต้อง ครบถ้วน ในฐานะพลเมืองดีและใช้สิทธิประโยชน์ทางภาษีต่าง ๆ ที่กฎหมายกำหนดไว้ไปใช้ลดหย่อนภาษีเงินได้ประจำปี เพื่อบรรเทาภาระภาษีให้น้อยลง ไม่ต้องเสียภาษีมากจนเกินไป รวมถึงไม่ต้องชำระภาษีเพิ่มหรือเสียเบี้ยปรับโดยใช่เหตุ และยิ่งถ้าเราวางแผนภาษีได้เป็นอย่างดี ก็จะเป็นการเพิ่มพูนความมั่งคั่งได้เป็นอย่างดีด้วย "
      }
      open={openTaxPlanInfo}
      onClose={() => {
        setOpenTaxPlanInfo(false);
      }}
    />
  );
};

const ViewStepThreeTaxPlan = ({
  onClick,
  setForm,
  form,
  error,
  setError,
  openTaxPlanInfo,
  setOpenTaxPlanInfo,
}) => {
  const taxData = useTaxStore((state) => state.taxData);
  const setTaxData = useTaxStore((state) => state.setTaxData);

  const [open, setOpen] = useState(1);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const { deduction, setDeduction } = useTaxPlanContext();

  const displaySumBudget = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_life) +
      Number(form?.tax_plan_insurance_health) +
      Number(form?.tax_plan_insurance_annuity) +
      Number(form?.tax_fund_provident) +
      Number(form?.tax_fund_nsf) +
      Number(form?.tax_plan_rmf) +
      Number(form?.tax_plan_ssf) +
      Number(form?.tax_plan_political_donate) +
      Number(form?.tax_fund_esg);
    return sum;
  };
  const displayDeducExpenses = useMemo(() => {
    let deduction_expenses = 0;
    if (form?.tax_income / 2 < 100000)
      deduction_expenses = form?.tax_income / 2;
    else return 100000;
  }, [form?.deduction_expenses]);

  const displayTaxPaid = useMemo(
    () => calculateTax(form.tax_income),
    [form?.tax_income]
  );

  // Sum ค่าลดหย่อนก่อนวางแผน
  const getBeforeDeduct = useMemo(() => {
    let sum = 0;
    deduction?.map((row) => {
      sum += Number(row?.amount);
    });
    return sum;
  });

  // mark

  // Sum ค่าลดหย่อนทั้งหมด
  const displaySumDeduction = () => {
    let sum = 0;
    sum =
      Number(getBeforeDeduct) +
      Number(form?.tax_plan_insurance_life) +
      Number(form?.tax_plan_insurance_health) +
      Number(form?.tax_plan_insurance_annuity) +
      Number(form?.tax_fund_provident) +
      Number(form?.tax_fund_nsf) +
      Number(form?.tax_plan_rmf) +
      Number(form?.tax_plan_ssf) +
      Number(form?.tax_plan_political_donate) +
      Number(form?.tax_fund_esg);
    return sum;
  };

  const getCaltax = () => {
    let result = 0;
    result =
      Number(form?.tax_income) -
      Number(form?.deduction_expenses) -
      Number(getBeforeDeduct);
    return calculateTax(result);
  };

  // Sum ภาษีหลังวางแผน
  const displaySumAfterTaxPaid = () => {
    let result = 0;
    result =
      Number(form?.tax_income) -
      Number(form?.deduction_expenses) -
      Number(displaySumDeduction());
    return calculateTax(result);
  };

  // เงินได้สุทธิ
  const getNetIncome = () => {
    let sum = 0;
    sum =
      Number(form?.tax_income) -
      Number(form?.deduction_expenses) -
      Number(displaySumDeduction());
    return sum;
  };

  const getSumAllInsurance = useMemo(
    () => getcalSum(form?.tax_plan_insurance_life, form?.insurance_life),
    [form?.tax_plan_insurance_life, form?.insurance_life]
  );

  // Sum ประกันชีวิต กับ ประกันสุขภาพ
  const getSumAllInsuranceHelth = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_life) +
      Number(form?.insurance_life) +
      Number(form?.tax_plan_insurance_health) +
      Number(form?.insurance_health);
    return sum;
  };

  const getSumAnnuity = () => {
    let result = 0;
    result = form?.tax_plan_insurance_annuity + form?.insurance_annuity;
    return result;
  };

  const getSumProvident = () => {
    let result = 0;
    result = Number(form?.tax_fund_provident) + Number(form?.fund_provident);
    return Number(result);
  };

  const getSumNSF = () => {
    let result = 0;
    result = form?.tax_fund_nsf + form?.fund_nsf;
    return Number(result);
  };

  const getSumSSF = () => {
    let result = 0;
    result = form?.tax_plan_ssf + form?.fund_provident_ssf_value;
    return Number(result);
  };

  const getSumRMF = useMemo(
    () => getcalSum(form?.tax_plan_rmf, form?.fund_provident_rmf_value),
    [form?.tax_plan_rmf, form?.fund_provident_rmf_value]
  );
  const getSumESG = useMemo(
    () => getcalSum(form?.tax_fund_esg, form?.fund_esg),
    [form?.tax_fund_esg, form?.fund_esg]
  );

  const getSumFundProvident = () => {
    let results = 0;
    results = Number(form?.tax_fund_provident) + Number(form?.fund_provident);
    return Number(results);
  };

  const RemainInsuaLife = useMemo(
    () => getcalPaid(getSumAllInsurance, 100000),
    [getSumAllInsurance, 100000]
  );

  const RemianFund = useMemo(
    () =>
      getcalPaid(
        form?.tax_plan_political_donate + form?.political_donate,
        10000
      ),
    [form?.tax_plan_political_donate + form?.political_donate, 10000]
  );
  const getDonate = () => {
    let sum = 0;
    sum = form?.tax_plan_political_donate + form?.political_donate;
    return numeral(sum)?.format("0,0");
  };

  // Find 15 percent of tax_incom
  const taxIncome15pc =
    form?.tax_income * 0.15 > 500000 ? 500000 : form?.tax_income * 0.15;

  const RemainProvident = useMemo(() => {
    // console.log("getSumFundProvident", getSumFundProvident());
    // console.log("taxIncome15pc", taxIncome15pc);
    return getcalPaid(getSumFundProvident(), taxIncome15pc);
  }, [getSumFundProvident(), taxIncome15pc]);

  const RemainNSF = () => {
    let results = 0;
    results = 13200 - (form?.tax_fund_nsf + form?.fund_nsf);
    return Number(results);
  };

  const RemainRMF = () => {
    let results = 0;
    results = 200000 - (form?.tax_plan_rmf + form?.fund_provident_rmf_value);
    return Number(results);
  };

  const RemainESG = () => {
    let results = 0;
    results = 100000 - (form?.tax_fund_esg + form?.fund_esg);
    return Number(results);
  };

  const RemainSSF = () => {
    let results = 0;
    results = 200000 - (form?.tax_plan_ssf + form?.fund_provident_ssf_value);
    return Number(results);
  };

  const RemainInsuaHealth = () => {
    let result = 0;
    result = 100000 - getSumAllInsuranceHelth();
    return Number(result);
  };

  const getPercentBar = useMemo(
    () => getPercent(getCaltax(), displayTaxPaid),
    [getCaltax(), displayTaxPaid]
  );

  const getPercentBarAfter = useMemo(
    () => getPercent(displaySumAfterTaxPaid(), displayTaxPaid),
    [displaySumAfterTaxPaid(), displayTaxPaid]
  );

  const getPercentInsuaranceLife = useMemo(
    () =>
      getPercent(form?.tax_plan_insurance_life + form?.insurance_life, 100000),
    [form?.tax_plan_insurance_life + form?.insurance_life, 100000]
  );

  let valuehealth = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_health) + Number(form?.insurance_health);

    return Number(sum);
  };

  const getPercentBarHealth = useMemo(
    () => getPercent(Number(valuehealth()), 25000),
    [valuehealth, 25000]
  );

  const getPercentDonate = useMemo(
    () =>
      getPercent(
        form?.tax_plan_political_donate + form?.political_donate,
        10000
      ),
    [form?.tax_plan_political_donate, form?.political_donate, 10000]
  );

  const getPercentBarAnnutity = useMemo(
    () =>
      getPercent(
        form.tax_plan_insurance_annuity + form?.insurance_annuity,
        200000
      ),
    [form?.tax_plan_insurance_annuity, 200000]
  );

  const getPercentBarProvident = useMemo(
    () =>
      getPercent(form.tax_fund_provident + form?.fund_provident, taxIncome15pc),
    [form?.tax_fund_provident, taxIncome15pc]
  );

  const getPercentBarNSF = useMemo(
    () => getPercent(form.tax_fund_nsf + form?.fund_nsf, 13200),
    [form?.tax_fund_nsf, 13200]
  );

  const getPercentBarRMF = useMemo(
    () =>
      getPercent(form.tax_plan_rmf + form?.fund_provident_rmf_value, 200000),
    [form?.tax_plan_rmf, 200000]
  );

  const getPercentBarESG = useMemo(
    () => getPercent(form.tax_fund_esg + form?.fund_esg, 100000),
    [form?.tax_fund_esg, 100000]
  );

  const getPercentBarSSF = useMemo(
    () =>
      getPercent(form.tax_plan_ssf + form?.fund_provident_ssf_value, 200000),
    [form?.tax_plan_ssf, 200000]
  );

  // ประกันชีวิต
  const onInsuranceLifeChange = (e) => {
    let value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_insurance_life: false,
      });
      return setForm({ ...form, tax_plan_insurance_life: "" });
    } else {
      setError({
        ...error,
        tax_plan_insurance_life: false,
      });
    }

    if (value + Number(form?.insurance_life) > 100000)
      return setError({
        ...error,
        tax_plan_insurance_life: true,
      });

    setForm({ ...form, tax_plan_insurance_life: value });
  };

  // ประกันสุขภาพ
  const onInsuranceHelthChange = (e) => {
    let value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_insurance_health: false,
      });
      return setForm({ ...form, tax_plan_insurance_health: "" });
    } else {
      setError({
        ...error,
        tax_plan_insurance_health: false,
      });
    }

    if (value + Number(form?.insurance_health) > 25000)
      return setError({
        ...error,
        tax_plan_insurance_health: true,
      });

    setForm({ ...form, tax_plan_insurance_health: value });
  };

  const onFundChange = (e) => {
    if (isNaN(e.target.value)) return;
    setForm({ ...form, tax_plan_rmf: Number(e.target.value) });
  };

  const onChangeFundProvident = (e) => {
    let value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_fund_provident: false,
      });
      return setForm({ ...form, tax_fund_provident: "" });
    } else {
      setError({
        ...error,
        tax_fund_provident: false,
      });
    }

    if (value + Number(form?.fund_provident) > taxIncome15pc)
      return setError({
        ...error,
        tax_fund_provident: true,
      });

    setForm({ ...form, tax_fund_provident: value });
  };

  const onDonateChange = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_political_donate: false,
      });
      return setForm({ ...form, tax_plan_political_donate: "" });
    } else {
      setError({
        ...error,
        tax_plan_political_donate: false,
      });
    }

    if (value + Number(form?.political_donate) > 10000)
      return setError({
        ...error,
        tax_plan_political_donate: true,
      });

    setForm({ ...form, tax_plan_political_donate: value });
  };

  const onInsuranceAnnuity = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_insurance_annuity: false,
      });
      return setForm({ ...form, tax_plan_insurance_annuity: "" });
    } else {
      setError({
        ...error,
        tax_plan_insurance_annuity: false,
      });
    }

    if (value + Number(form?.insurance_annuity) > 200000)
      return setError({
        ...error,
        tax_plan_insurance_annuity: true,
      });

    setForm({ ...form, tax_plan_insurance_annuity: value });
  };

  const onChangeNSF = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_fund_nsf: false,
      });
      return setForm({ ...form, tax_fund_nsf: "" });
    } else {
      setError({
        ...error,
        tax_fund_nsf: false,
      });
    }

    if (value + Number(form?.fund_nsf) > 13200)
      return setError({
        ...error,
        tax_fund_nsf: true,
      });

    setForm({ ...form, tax_fund_nsf: value });
  };

  const onChangeRMF = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_rmf: false,
      });
      return setForm({ ...form, tax_plan_rmf: "" });
    } else {
      setError({
        ...error,
        tax_plan_rmf: false,
      });
    }

    if (value + Number(form?.fund_provident_rmf_value) > 200000)
      return setError({
        ...error,
        tax_plan_rmf: true,
      });

    setForm({ ...form, tax_plan_rmf: value });
  };

  const onChangeESG = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_fund_esg: false,
      });
      return setForm({ ...form, tax_fund_esg: "" });
    } else {
      setError({
        ...error,
        tax_fund_esg: false,
      });
    }

    if (value + Number(form?.fund_esg) > 100000)
      return setError({
        ...error,
        tax_fund_esg: true,
      });

    setForm({ ...form, tax_fund_esg: value });
  };

  const onChangeSSF = (e) => {
    const value = numberWithoutComma(e.target.value);

    if (isNaN(value)) {
      setError({
        ...error,
        tax_plan_ssf: false,
      });
      return setForm({ ...form, tax_plan_ssf: "" });
    } else {
      setError({
        ...error,
        tax_plan_ssf: false,
      });
    }

    if (value + Number(form?.fund_provident_ssf_value) > 200000)
      return setError({
        ...error,
        tax_plan_ssf: true,
      });

    setForm({ ...form, tax_plan_ssf: value });
  };

  const checkPercent = () => {
    let result = 0;
    result =
      ((getCaltax() - Number(displaySumAfterTaxPaid())) / getCaltax()) * 100;

    if (isNaN(result)) {
      return 0;
    }

    return Number(result)?.toFixed(2);
  };

  const checkSum5values = () => {
    let sum = 0;
    sum =
      Number(form?.tax_plan_insurance_annuity) +
      Number(form?.insurance_annuity) +
      Number(form?.tax_fund_provident) +
      Number(form?.fund_provident) +
      Number(form?.tax_fund_nsf) +
      Number(form?.fund_nsf) +
      Number(form?.tax_plan_rmf) +
      Number(form?.fund_provident_rmf_value) +
      Number(form?.tax_plan_ssf) +
      Number(form?.fund_provident_ssf_value);
    return Number(sum);
  };

  //----------------------------------------------------------------------------//

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setForm({
        ...form,
        deduct_summary_af: displaySumDeduction(),
      });
    }
    return () => {
      ignore = true;
    };
  }, [
    form?.deduct_summary,
    form?.tax_plan_insurance_life,
    form?.tax_plan_insurance_health,
    form?.tax_plan_insurance_annuity,
    form?.tax_fund_provident,
    form?.tax_fund_nsf,
    form?.tax_plan_rmf,
    form?.tax_plan_ssf,
    form?.tax_plan_political_donate,
    form?.tax_fund_esg,
  ]);

  // mark
  useEffect(() => {
    const newForm = {
      ...form,
      after_tax_paid: displaySumAfterTaxPaid(),
      final_tax: displaySumAfterTaxPaid() - form.tax_withholding,
      net_income: getNetIncome(),
    };
    setForm(newForm);
    setTaxData(newForm);
  }, [form.tax_withholding, displaySumAfterTaxPaid()]);

  // mark
  useEffect(() => {
    setError({
      ...error,
      sum_all_insurance: false,
    });
  }, [getSumAllInsuranceHelth()]);

  useEffect(() => {
    let ignore = false;
    const targetElement = document.getElementById("first_reload");

    if (targetElement && !ignore) {
      const targetOffset =
        targetElement.getBoundingClientRect().top + window.scrollY + -190;

      window.scrollTo({
        top: targetOffset,
        behavior: "smooth",
      });
    }

    return () => {
      ignore = true;
    };
  }, []);

  // console.log("form : ", form);

  return (
    <div id="first_reload">
      <div className="my-9">
        {/* <HalfChartMain
          text
          beforevalue={getCaltax()}
          // aftervalue={100}
          aftervalue={displaySumBudget() ? displaySumAfterTaxPaid() : 0}
          maxvalue={displayTaxPaid}
          value={checkPercent()}
        /> */}

        {/*------------------ ก่อนวางแผน -------------------*/}
        <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5 mb-4`}>
          <div className=" flex justify-between mb-[6px]">
            <div className=" text-red-500 text-sm font-semibold">
              ก่อนวางแผน
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {numeral(getCaltax())?.format("0,0")} บาท
            </div>
          </div>
          <Progress value={getPercentBar} color="red" className="bg-white" />
          <div className="text-right text-xs text-maingray mt-2.5 -mb-2.5">
            * ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย
          </div>
        </div>

        {/*------------------ หลังวางแผน -------------------*/}

        {displaySumBudget() === 0 ? (
          ""
        ) : (
          <div className={`${STYLE_CARD_BG} px-5 pt-3 pb-5 mb-4`}>
            <div className=" flex justify-between mb-[6px]">
              <div className=" text-maingreen text-sm font-semibold">
                หลังวางแผน
              </div>
              <div className={STYLE_NUMBER_PLAN_BAR}>
                {numeral(displaySumAfterTaxPaid())?.format("0,0")} บาท
              </div>
            </div>
            <Progress
              value={getPercentBarAfter}
              color="green"
              className="bg-white"
            />
            <div className="text-right text-xs text-maingray mt-2.5 -mb-2.5">
              * ยังไม่ได้คำนวณ ภาษีหัก ณ ที่จ่าย
            </div>
          </div>
        )}

        {/*------------------ ภาษีหัก ณ ที่จ่าย -------------------*/}
        <div className={`${STYLE_CARD_BG} px-5 mb-4`}>
          <div className=" flex justify-between ">
            <div className=" text-mainblue text-sm font-semibold">
              ภาษีหัก ณ ที่จ่าย
            </div>
            <div className={STYLE_NUMBER_PLAN_BAR}>
              {numeral(form?.tax_withholding)?.format("0,0")} บาท
            </div>
          </div>
        </div>

        {/*----------- Card แสดงผลของยอดสรุปภาษีทั้งหมด ----------*/}
        <CardConclusionTax form={form} />

        {/*--------------------- วางแผนภาษี --------------------*/}
        <React.Fragment>
          <div className=" my-[14px] text-center text-maincyan font-semibold text-[28px]  font-IBMPlexSansThai">
            มาวางแผนภาษีกันเถอะ !
          </div>
          <CardMain
            icon={true}
            title="วางแผนภาษี"
            onClick={() => {
              setOpenTaxPlanInfo(true);
            }}
            panel={
              <div className="mt-3">
                <div className=" flex flex-col justify-center items-center">
                  <div className="text-xl font-semibold text-maincyan text-center">
                    {numeral(displaySumBudget())?.format("0,0")}{" "}
                    {displaySumBudget() ? "บาท" : ""}
                  </div>
                  <div className=" text-sm text-maingray font-medium">
                    งบประมาณที่ใช้
                  </div>
                </div>
                <div className=" mt-5 border-t border-gray55">
                  <div className="text-xs text-maingray text-center mt-1">
                    รายละเอียด
                  </div>
                </div>

                {/*---------- ประกันชีวิต ----------*/}
                <Accordion
                  open={open === 1}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 1 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 1 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(1);
                    }}
                  >
                    {open === 1 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>ประกันชีวิต</div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>ประกันชีวิต</div>
                        <span className=" text-maincyan font-medium ">
                          {form?.tax_plan_insurance_life
                            ? numeral(form?.tax_plan_insurance_life)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className="  ">
                      {Number(form?.insurance_health + form?.insurance_life) ===
                        100000 && !form.tax_plan_insurance_life ? (
                        <div className=" text-center text-red-500 font-medium mb-1 text-lg ">
                          เต็มสิทธิ
                          <div className=" text-center text-red-500 font-medium mb-1 text-xs">
                            ประกันชีวิตและประกันสุขภาพรวมกันไม่เกิน 100,000 บาท
                          </div>
                        </div>
                      ) : (
                        <>
                          {getSumAllInsuranceHelth() > 100000 && (
                            <div className=" text-center text-red-500 font-medium mb-1 text-lg ">
                              เกินสิทธิ
                              <div className=" text-center text-red-500 font-medium mb-1 text-sm">
                                ประกันชีวิตและประกันสุขภาพรวมกันไม่เกิน 100,000
                                บาท
                              </div>
                            </div>
                          )}
                          <div className="flex justify-center mb-2">
                            {error?.tax_plan_insurance_life && (
                              <ErrorMain errortext="ไม่เกิน 100,000 บาท" />
                            )}
                          </div>
                          <div className={STYLE_ACCORDION_BODY_TAX}>
                            <div>ซื้อเพิ่ม</div>
                           <NumbericValidateRed
                              maxLength={7}
                              value={form?.tax_plan_insurance_life}
                              placeholder="0"
                              onChange={onInsuranceLifeChange}
                              thousandSeparator=","
                              inputMode="decimal"
                              pattern="[0-9],*"
                              className={
                                error?.tax_plan_insurance_life
                                  ? STYLE_INPUT_INSET_ERROR
                                  : STYLE_INPUT_INSET
                              }
                            />
                            <div>บาท</div>
                          </div>

                          <div className={STYLE_ACCORDION_BODY_TAX_2}>
                            ซื้อไปแล้ว{" "}
                            <span className={STYLE_ACCORDION_SUBTITLE}>
                              {form?.insurance_life
                                ? numeral(form?.insurance_life)?.format("0,0")
                                : 0}
                            </span>
                            บาท เพิ่มได้อีก{" "}
                            <span className={STYLE_ACCORDION_SUBTITLE}>
                              {numeral(RemainInsuaLife)?.format("0,0")}
                            </span>
                            บาท{" "}
                          </div>
                        </>
                      )}

                      <div className={STYLE_ACCORDION_BODY_TAX_3}>
                        รวม {numeral(getSumAllInsurance)?.format("0,0")} /
                        100,000 ต่อปี
                      </div>
                      <div className="px-10 relative w-full">
                        <Progress
                          value={getPercentInsuaranceLife}
                          size="sm"
                          color="blue-gray"
                          barProps={{
                            className: "bg-maincyan ",
                          }}
                        />
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- ประกันสุขภาพ ----------*/}
                <Accordion
                  open={open === 2}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 2 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 2 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(2);
                    }}
                  >
                    {open === 2 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>ประกันสุขภาพ</div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>ประกันสุขภาพ</div>
                        <span className=" text-maincyan font-medium  ">
                          {form?.tax_plan_insurance_health
                            ? numeral(form?.tax_plan_insurance_health)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className="  ">
                      {Number(form?.insurance_health + form?.insurance_life) ===
                        100000 && !form?.tax_plan_insurance_health ? (
                        <div className=" text-center text-red-500 font-medium mb-1 text-lg ">
                          เต็มสิทธิ
                          <div className=" text-center text-red-500 font-medium mb-1 text-xs">
                            ประกันชีวิตและประกันสุขภาพรวมกันไม่เกิน 100,000 บาท
                          </div>
                        </div>
                      ) : (
                        <>
                          {/* กรณีซื้อประกันสุขภาพได้ */}
                          {getSumAllInsuranceHelth() > 100000 && (
                            <div className=" text-center text-red-500 font-medium mb-1 text-lg ">
                              เกินสิทธิ
                              <div className=" text-center text-red-500 font-medium mb-1 text-sm">
                                ประกันชีวิตและประกันสุขภาพรวมกันไม่เกิน 100,000
                                บาท
                              </div>
                            </div>
                          )}
                          <div className="flex justify-center mb-2">
                            {error?.tax_plan_insurance_health && (
                              <ErrorMain errortext="ไม่เกิน 25,000 บาท" />
                            )}
                          </div>

                          <div className={STYLE_ACCORDION_BODY_TAX}>
                            <div>ซื้อเพิ่ม</div>
                           <NumbericValidateRed
                              maxLength={6}
                              value={form?.tax_plan_insurance_health}
                              onChange={onInsuranceHelthChange}
                              placeholder="0"
                              thousandSeparator=","
                              inputMode="decimal"
                              pattern="[0-9],*"
                              className={
                                error?.tax_plan_insurance_health
                                  ? STYLE_INPUT_INSET_ERROR
                                  : STYLE_INPUT_INSET
                              }
                            />
                            <div>บาท</div>
                          </div>
                          <div className={STYLE_ACCORDION_BODY_TAX_2}>
                            ซื้อไปแล้ว{" "}
                            <span className={STYLE_ACCORDION_SUBTITLE}>
                              {form?.insurance_health
                                ? numeral(form?.insurance_health)?.format("0,0")
                                : 0}
                            </span>
                            บาท เพิ่มได้อีก{" "}
                            <span className={STYLE_ACCORDION_SUBTITLE}>
                              {numeral(
                                25000 -
                                  (form?.insurance_health +
                                    form?.tax_plan_insurance_health)
                              )?.format("0,0")}
                            </span>
                            บาท{" "}
                          </div>
                        </>
                      )}

                      <div className=" px-10">
                        <div className={STYLE_ACCORDION_BODY_TAX_3}>
                          รวม{" "}
                          {numeral(
                            Number(form?.insurance_health) +
                              Number(form?.tax_plan_insurance_health)
                          )?.format("0,0")}{" "}
                          / 25,000 ต่อปี
                        </div>
                        <Progress
                          value={getPercentBarHealth}
                          size="sm"
                          className="bg-white"
                          barProps={{
                            className: "bg-maincyan ",
                          }}
                        />
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- ประกันชีวิตแบบบำนาญ ----------*/}
                <Accordion
                  open={open === 3}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 3 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 3 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(3);
                    }}
                  >
                    {open === 3 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            ประกันชีวิตแบบบำนาญ
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          ประกันชีวิตแบบบำนาญ
                        </div>
                        <span className=" text-maincyan font-medium  ">
                          {form?.tax_plan_insurance_annuity
                            ? numeral(form?.tax_plan_insurance_annuity)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className="">
                      <div className="flex justify-center mb-2">
                        {error?.tax_plan_insurance_annuity && (
                          <ErrorMain errortext="ไม่เกิน 200,000 บาท" />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                       <NumbericValidateRed
                          maxLength={7}
                          value={form?.tax_plan_insurance_annuity}
                          onChange={onInsuranceAnnuity}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_insurance_annuity
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />
                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.insurance_annuity
                            ? numeral(form?.insurance_annuity)?.format("0,0")
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(
                            200000 -
                              form?.insurance_annuity -
                              form?.tax_plan_insurance_annuity
                          )?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>

                      <div className=" px-10">
                        <div className={STYLE_ACCORDION_BODY_TAX_3}>
                          รวม {numeral(getSumAnnuity())?.format("0,0")} /
                          200,000 ต่อปี
                        </div>
                        <Progress
                          value={getPercentBarAnnutity}
                          size="sm"
                          className="bg-white"
                          barProps={{
                            className: "bg-maincyan ",
                          }}
                        />
                      </div>
                      <div>
                        <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
                          <span className="text-mainblue font-semibold ">
                            ตอนนี้ใช้ไปแล้ว
                            <span className="px-1">
                              {numeral(checkSum5values()).format("0,0")}
                            </span>
                            บาท*
                            {checkSum5values() > 500000 && (
                              <div className="text-red-500 font-semibold text-center mt-1">
                                เกินสิทธิ
                              </div>
                            )}
                          </span>
                        </div>
                        <div className={STYLE_DOK_JAN}>
                          * กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ,
                          ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
                          กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน
                          500,000 บาท
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- เงินสะสมกองทุนสำรองเลี้ยงชีพ ----------*/}
                <Accordion
                  open={open === 4}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 4 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 4 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(4);
                    }}
                  >
                    {open === 4 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            เงินสะสมกองทุนสำรองเลี้ยงชีพ
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          เงินสะสมกองทุนสำรองเลี้ยงชีพ
                        </div>
                        <div className=" text-maincyan font-medium ">
                          {form?.tax_fund_provident
                            ? numeral(form?.tax_fund_provident)?.format("0,0")
                            : ""}
                        </div>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_fund_provident && (
                          <ErrorMain
                            errortext={`ไม่เกิน ${numeral(
                              taxIncome15pc
                            )?.format("0,0")} บาท`}
                          />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                       <NumbericValidateRed
                          maxLength={7}
                          value={form?.tax_fund_provident}
                          onChange={onChangeFundProvident}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_fund_provident
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.fund_provident
                            ? numeral(form?.fund_provident)?.format("0,0")
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(RemainProvident)?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>

                      <div className={STYLE_ACCORDION_BODY_TAX_3}>
                        รวม {numeral(getSumFundProvident())?.format("0,0")} /{" "}
                        {numeral(taxIncome15pc)?.format("0,0")} ต่อปี
                      </div>

                      <Progress
                        value={getPercentBarProvident}
                        size="sm"
                        className="bg-white"
                        barProps={{
                          className: "bg-maincyan ",
                        }}
                      />
                    </div>
                    {/* <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
                  <span className="text-mainblue font-semibold ">
                    ตอนนี้ใช้ไปแล้ว {numeral(checkSum5values()).format("0,0")}{" "}
                    บาท
                    {checkSum5values() > 500000 && (
                      <div className="text-red-500 font-semibold text-center mt-1">
                        เกินสิทธิ
                      </div>
                    )}
                  </span>
                </div> */}
                    <div>
                      <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
                        <span className="text-mainblue font-semibold ">
                          ตอนนี้ใช้ไปแล้ว
                          <span className=" px-1">
                            {numeral(checkSum5values()).format("0,0")}
                          </span>
                          บาท*
                          {checkSum5values() > 500000 && (
                            <div className="text-red-500 font-semibold text-center mt-1">
                              เกินสิทธิ
                            </div>
                          )}
                        </span>
                      </div>
                      <div className={STYLE_DOK_JAN}>
                        * กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ,
                        ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
                        กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000
                        บาท
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- เงินสะสมการออมแห่งชาติ ----------*/}
                <Accordion
                  open={open === 5}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 5 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 5 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(5);
                    }}
                  >
                    {open === 5 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            เงินสะสมการออมแห่งชาติ
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div
                        onClick={onClick}
                        className="flex justify-between items-center w-full font-IBMPlexSansThai"
                      >
                        <div className={STYLE_HEAD_GRAY}>
                          เงินสะสมการออมแห่งชาติ{" "}
                        </div>
                        <span className=" text-maincyan font-medium  ">
                          {form?.tax_fund_nsf
                            ? numeral(form?.tax_fund_nsf)?.format("0,0")
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_fund_nsf && (
                          <ErrorMain errortext={`ไม่เกิน 13,200 บาท`} />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                       <NumbericValidateRed
                          maxLength={6}
                          value={form?.tax_fund_nsf}
                          onChange={onChangeNSF}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_fund_nsf
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.fund_nsf
                            ? numeral(form?.fund_nsf)?.format("0,0")
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(RemainNSF())?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>

                      <div className={STYLE_ACCORDION_BODY_TAX_3}>
                        รวม {numeral(getSumNSF())?.format("0,0")} / 13,200 ต่อปี
                      </div>
                      <Progress
                        value={getPercentBarNSF}
                        size="sm"
                        className="bg-white"
                        barProps={{
                          className: " bg-maincyan ",
                        }}
                      />
                    </div>
                    <div>
                      <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
                        <span className="text-mainblue font-semibold ">
                          ตอนนี้ใช้ไปแล้ว
                          <span className="px-1">
                            {numeral(checkSum5values()).format("0,0")}
                          </span>
                          บาท*
                          {checkSum5values() > 500000 && (
                            <div className="text-red-500 font-semibold text-center mt-1">
                              เกินสิทธิ
                            </div>
                          )}
                        </span>
                      </div>
                      <div className={STYLE_DOK_JAN}>
                        * กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ,
                        ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
                        กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000
                        บาท
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน (Thailand ESG) ----------*/}
                <Accordion
                  open={open === 6}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 6 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 6 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(6);
                    }}
                  >
                    {open === 6 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน
                            (Thailand ESG)
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          ค่าซื้อหน่วยลงทุนกองทุนรวมไทยเพื่อความยั่งยืน
                          (Thailand ESG)
                        </div>
                        <span className=" text-maincyan font-medium  ">
                          {form?.tax_fund_esg
                            ? numeral(form?.tax_fund_esg)?.format("0,0")
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_fund_esg && (
                          <ErrorMain errortext={`ไม่เกิน 100,000 บาท`} />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                       <NumbericValidateRed
                          maxLength={7}
                          value={form?.tax_fund_esg}
                          onChange={onChangeESG}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_fund_esg
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.fund_esg
                            ? numeral(form?.fund_esg)?.format("0,0")
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(RemainESG())?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>

                      <div className={STYLE_ACCORDION_BODY_TAX_3}>
                        รวม {numeral(getSumESG)?.format("0,0")} / 100,000 ต่อปี
                      </div>

                      <Progress
                        value={getPercentBarESG}
                        size="sm"
                        className="bg-white"
                        barProps={{
                          className: " bg-maincyan ",
                        }}
                      />
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- กองทุนรวมเพื่อการเลี้ยงชีพ (RMF) ----------*/}
                <Accordion
                  open={open === 7}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 7 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 7 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(7);
                    }}
                  >
                    {open === 7 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          กองทุนรวมเพื่อการเลี้ยงชีพ (RMF){" "}
                        </div>
                        <span className=" text-maincyan font-medium  ">
                          {form?.tax_plan_rmf
                            ? numeral(form?.tax_plan_rmf)?.format("0,0")
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_plan_rmf && (
                          <ErrorMain errortext={`ไม่เกิน 200,000 บาท`} />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                       <NumbericValidateRed
                          maxLength={7}
                          value={form?.tax_plan_rmf}
                          onChange={onChangeRMF}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_rmf
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.fund_provident_rmf_value
                            ? numeral(form?.fund_provident_rmf_value)?.format(
                                "0,0"
                              )
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(RemainRMF())?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>

                      <div className={STYLE_ACCORDION_BODY_TAX_3}>
                        รวม {numeral(getSumRMF)?.format("0,0")} / 200,000 ต่อปี
                      </div>

                      <Progress
                        value={getPercentBarRMF}
                        size="sm"
                        className="bg-white"
                        barProps={{
                          className: "bg-maincyan ",
                        }}
                      />
                    </div>
                    <div>
                      <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
                        <span className="text-mainblue font-semibold ">
                          ตอนนี้ใช้ไปแล้ว{" "}
                          {numeral(checkSum5values()).format("0,0")} บาท*
                          {checkSum5values() > 500000 && (
                            <div className="text-red-500 font-semibold text-center mt-1">
                              เกินสิทธิ
                            </div>
                          )}
                        </span>
                      </div>
                      <div className={STYLE_DOK_JAN}>
                        * กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ,
                        ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
                        กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000
                        บาท
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- กองทุนรวมเพื่อการออม (SSF) ----------*/}
                <Accordion
                  open={open === 8}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 8 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 8 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(8);
                    }}
                  >
                    {open === 8 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            กองทุนรวมเพื่อการออม (SSF)
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div className={STYLE_HEAD_GRAY}>
                          กองทุนรวมเพื่อการออม (SSF){" "}
                        </div>
                        <span className=" text-maincyan font-medium  ">
                          {form?.tax_plan_ssf
                            ? numeral(form?.tax_plan_ssf)?.format("0,0")
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_plan_ssf && (
                          <ErrorMain errortext={`ไม่เกิน 200,000 บาท`} />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>ซื้อเพิ่ม</div>

                       <NumbericValidateRed
                          maxLength={7}
                          value={form?.tax_plan_ssf}
                          onChange={onChangeSSF}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_ssf
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        ซื้อไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.fund_provident_ssf_value
                            ? numeral(form?.fund_provident_ssf_value)?.format(
                                "0,0"
                              )
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {numeral(RemainSSF())?.format("0,0")}
                        </span>
                        บาท{" "}
                      </div>

                      <div className={STYLE_ACCORDION_BODY_TAX_3}>
                        รวม {numeral(getSumSSF())?.format("0,0")} / 200,000
                        ต่อปี
                      </div>

                      <Progress
                        value={getPercentBarSSF}
                        size="sm"
                        className="bg-white"
                        barProps={{
                          className: "bg-maincyan ",
                        }}
                      />
                    </div>
                    <div>
                      <div className=" animate-fade-up  mt-5 text-center font-medium text-md md:text-lg bg-white p-3 rounded-lg mx-[30px] ">
                        <span className="text-mainblue font-semibold ">
                          ตอนนี้ใช้ไปแล้ว{" "}
                          {numeral(checkSum5values()).format("0,0")} บาท*
                          {checkSum5values() > 500000 && (
                            <div className="text-red-500 font-semibold text-center mt-1">
                              เกินสิทธิ
                            </div>
                          )}
                        </span>
                      </div>
                      <div className={STYLE_DOK_JAN}>
                        * กองทุนสำรองเลี้ยงชีพ, เงินสะสมการออมแห่งชาติ,
                        ประกันชีวิตแบบบำนาญ, กองทุนรวมเพื่อการเลี้ยงชีพ(RMF),
                        กองทุนรวมเพื่อการออม(SSF) เมื่อรวมกันต้องไม่เกิน 500,000
                        บาท
                      </div>
                    </div>
                  </AccordionBody>
                </Accordion>

                {/*---------- เงินบริจาคแก่พรรคการเมือง ----------*/}
                <Accordion
                  open={open === 9}
                  icon={
                    <ICON_ARROW_UP_DOWN
                      className={`${
                        open === 9 ? "hidden" : ""
                      } transition-transform h-4 w-4`}
                    />
                  }
                >
                  <AccordionHeader
                    className={` ${
                      open === 9 ? STYLE_BF_ACCORDION : STYLE_AF_ACCORDION
                    }`}
                    onClick={() => {
                      handleOpen(9);
                    }}
                  >
                    {open === 9 ? (
                      <div onClick={onClick} className={STYLE_PARENT_HEAD_AC}>
                        <div>
                          <div className={STYLE_HEAD_AC}>
                            เงินบริจาคแก่พรรคการเมือง
                          </div>
                          <div className={STYLE_BORDER_YELLOW_AC}></div>
                        </div>
                        <ICON_ARROW_UP_DOWN className={`w-4 h-4 rotate-180`} />
                      </div>
                    ) : (
                      <div
                        onClick={onClick}
                        className="flex justify-between items-center w-full font-IBMPlexSansThai"
                      >
                        <div className={STYLE_HEAD_GRAY}>
                          เงินบริจาคแก่พรรคการเมือง{" "}
                        </div>
                        <span className=" text-maincyan font-medium  ">
                          {form?.tax_plan_political_donate
                            ? numeral(form?.tax_plan_political_donate)?.format(
                                "0,0"
                              )
                            : ""}
                        </span>
                      </div>
                    )}
                  </AccordionHeader>
                  <AccordionBody className=" font-IBMPlexSansThai">
                    <div className=" px-10">
                      <div className="flex justify-center mb-2">
                        {error?.tax_plan_political_donate && (
                          <ErrorMain errortext={`ไม่เกิน 10,000 บาท`} />
                        )}
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX}>
                        <div>บริจาคเพิ่ม</div>

                       <NumbericValidateRed
                          maxLength={6}
                          value={form?.tax_plan_political_donate}
                          onChange={onDonateChange}
                          placeholder="0"
                          thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9],*"
                          className={
                            error?.tax_plan_political_donate
                              ? STYLE_INPUT_INSET_ERROR
                              : STYLE_INPUT_INSET
                          }
                        />

                        <div>บาท</div>
                      </div>
                      <div className={STYLE_ACCORDION_BODY_TAX_2}>
                        บริจาคไปแล้ว{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {form?.political_donate
                            ? numeral(form?.political_donate)?.format("0,0")
                            : 0}
                        </span>
                        บาท เพิ่มได้อีก{" "}
                        <span className={STYLE_ACCORDION_SUBTITLE}>
                          {RemianFund}
                        </span>
                        บาท{" "}
                      </div>

                      <div className={STYLE_ACCORDION_BODY_TAX_3}>
                        {getDonate()}/ 10,000 ต่อปี
                      </div>
                      <Progress
                        value={getPercentDonate}
                        size="sm"
                        className="bg-white"
                        barProps={{
                          className: " bg-maincyan ",
                        }}
                      />
                    </div>
                  </AccordionBody>
                </Accordion>
              </div>
            }
          />
        </React.Fragment>
      </div>

      {/* {openTaxPlanInfo && (
        <TaxDrawer
          open={openTaxPlanInfo}
          setOpenTaxPlanInfo={setOpenTaxPlanInfo}
        />
      )} */}
    </div>
  );
};
export default ViewStepThreeTaxPlan;
