import React, { useMemo, useState } from "react";
import { useBalanceStore } from "../../../_store/balanceStore";
import numeral from "numeral";
import {
  OPTIONS_TARGET_INVEST,
  OPTIONS_TYPE_CAR,
  OPTIONS_TYPE_PLACE,
} from "../functions";
import { NumericFormat } from "react-number-format";
import { STYLE_BODY_INVEST, STYLE_INPUT_INSET } from "../../../utils/useStyle";

const numberWithoutComma = (value) => {
  let result = parseInt(value?.replace(/,/g, ""), 10);
  return result;
};

const ListPrivateMain = ({}) => {
  const setBalanceData = useBalanceStore((state) => state.setBalanceData);
  const balanceData = useBalanceStore((state) => state.balanceData);

  const sumDebtLongCarPersonal = useMemo(() => {
    const sum_debt_long_car_personal =
      balanceData?.debt_long_car_personal_list?.reduce((prev, curr) => {
        return prev + curr?.amount;
      }, 0);
    return sum_debt_long_car_personal;
  }, [balanceData]);

  const sumDebtLongPlacePersonal = useMemo(() => {
    const sum_debt_long_place_personal =
      balanceData?.debt_long_place_personal_list?.reduce((prev, curr) => {
        return prev + curr?.amount;
      }, 0);
    return sum_debt_long_place_personal;
  }, [balanceData]);

  return (
    <div className="mb-1.5">
      <div className=" border-b border-gray60 pb-3 mb-3"></div>
      <div className="flex flex-col items-center gap-2.5">
        <div className="flex justify-between p-2 bg-secoundblue rounded-[10px] w-full text-sm text-mainblue font-medium">
          <div>รายการ</div>
          <div>มูลค่าปัจจุบัน</div>
        </div>

        {balanceData?.debt_long_car_personal_list?.length > 0 && (
          <div className="w-full pb-2.5 border-b border-gray60">
            <div className="flex flex-col gap-1.5">
              <div className="text-sm text-mainblue font-medium mb-1.5">
                รถยนต์
              </div>
              <div className="flex flex-col gap-1.5">
                {/*---- รถยนต์ ----*/}
                {balanceData?.debt_long_car_personal_list?.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-between items-center text-xs font-normal"
                      >
                        <div>{item?.registration_car}</div>
                        <div className="text-mainblue">
                          {numeral(item?.amount).format("0,0")}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        )}

        {balanceData?.debt_long_place_personal_list?.length > 0 && (
          <div className="w-full pb-2.5 border-b border-gray60">
            <div className="flex flex-col gap-1.5">
              <div className="text-sm text-mainblue font-medium mb-1.5">
                ที่อยู่อาศัย
              </div>
              <div className="flex flex-col gap-1.5">
                {/*---- ที่อยู่อาศัย ----*/}
                {balanceData?.debt_long_place_personal_list?.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex justify-between items-center text-xs font-normal"
                      >
                        <div>{item?.address}</div>
                        <div className="text-mainblue">
                          {numeral(item?.amount).format("0,0")}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ListInvestmain = ({tempBalance,setTempBalance}) => {


  const carLists = useMemo(() => {
    return tempBalance?.debt_long_list
      ?.map((item) => item?.debt_long_cars)
      .flat()
      .filter((item) => item?.personal_or_investment === "investment");
  }, [tempBalance]);

  const placeLists = useMemo(() => {
    return tempBalance?.debt_long_list
      ?.map((item) => item?.debt_long_places)
      .flat()
      .filter((item) => item?.personal_or_investment === "investment");
  }, [tempBalance]);

  const otherLists = useMemo(() => {
    return tempBalance?.debt_long_list
      ?.map((item) => item?.debt_long_others)
      .flat()
      .filter((item) => item?.personal_or_investment === "investment");
  }, [tempBalance]);

  // const insuranceReturn = balanceData?.property_invest_list?.map((item) => {
  //   if (item?.invest_other_lists) {
  //     let tempData = tempInsuranceReturn
  //       .map((item2) => {
  //         if (!tempNameList?.includes(item2.title))
  //           return {
  //             amount: item2?.more_details?.policy_surrender_value,
  //             created: item2.created,
  //             reward_amount_per_year: 1,
  //             target_invest: {},
  //             title: item2.title,
  //             editable: false,
  //           };
  //       })
  //       .filter((item) => item !== undefined);
  //     return {
  //       ...item,
  //       invest_other_lists: [...item.invest_other_lists, ...tempData],
  //     };
  //   }
  //   return item;
  // });

  const onChangeInterestPlace = (value, index, field, searchField) => {
    let tempIndex = tempBalance?.debt_long_list?.findIndex(
      (item) => item?.options_debt_long === searchField);
      console.log("tempIndex",tempIndex,searchField,tempBalance?.debt_long_list);
    if(tempIndex < 0 || index < 0) return;

    let temp = tempBalance?.debt_long_list[tempIndex]
    
    temp[field][index] = {...temp[field][index], return_interest:  numberWithoutComma(value)};


    setTempBalance((prev) => {
      let clonePrev = [...prev.debt_long_list];
      clonePrev[tempIndex] = temp;
      return {
        ...prev,
        debt_long_list: clonePrev,
      }
    });
  };



  return (
    <div className="mb-1.5">
      <div className=" border-b border-gray60 pb-3 mb-3"></div>
      <div className="flex flex-col items-center gap-2.5">
        <div className="flex justify-between p-2 bg-secoundblue rounded-[10px] w-full text-sm text-mainblue font-medium">
          <div>รายการ</div>
          <div className="d:w-40  w-28 justify-start">มูลค่าปัจจุบัน</div>
          <div>ผลตอบแทนต่อปี (%)</div>
        </div>

        {carLists?.length > 0 && (
          <div className="w-full pb-2.5 border-b border-gray60">
            <div className="flex flex-col gap-1.5">
              <div className="text-sm text-mainblue font-medium mb-1.5">
                รถยนต์
              </div>
              <div className="flex flex-col gap-1.5">
                {/*---- รถยนต์ ----*/}
                {carLists?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex flex-row justify-between "
                    >
                      <div className="my-2.5 w-full ">
                        <div className={STYLE_BODY_INVEST}>
                          <div className=" flex gap-[6px] items-center">
                            {row?.registration_car}
                          </div>
                          <div
                            className={"flex  md:w-40  w-28 h-8  justify-start"}
                          >
                            {row?.current_price}
                          </div>
                          <div className=" flex items-center justify-end w-full ">
                            <NumericFormat
                              maxLength={11}
                              value={row?.return_interest}
                              placeholder="0"
                              onChange={(e) =>
                                onChangeInterestPlace(
                                  e.target.value,
                                  index,
                                  "debt_long_cars",
                                  "debt_car"
                                )
                              }
                              thousandSeparator=","
                              pattern="[0-9],*"
                              className={`${STYLE_INPUT_INSET} w-full`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {placeLists?.length > 0 && (
          <div className="w-full pb-2.5 border-b border-gray60">
            <div className="flex flex-col gap-1.5 w-full ">
              <div className="text-sm text-mainblue font-medium mb-1.5">
                ที่อยู่อาศัย
              </div>
              <div className="flex flex-col gap-1.5 w-full ">
                {/*---- ที่อยู่อาศัย ----*/}
                {placeLists?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex flex-row justify-between "
                    >
                      <div className="my-2.5 w-full ">
                        <div className={STYLE_BODY_INVEST}>
                          <div className=" flex gap-[6px] items-center">
                            {row?.address_place}
                          </div>
                          <div
                            className={"flex  md:w-40  w-28 h-8  justify-start"}
                          >
                            {row?.current_price}
                          </div>
                          <div className=" flex items-center justify-end w-full ">
                            <NumericFormat
                              maxLength={11}
                              value={row?.return_interest}
                              placeholder="0"
                              onChange={(e) =>
                                onChangeInterestPlace(
                                  e.target.value,
                                  index,
                                  "debt_long_places",
                                  "debt_place"
                                )
                              }
                              thousandSeparator=","
                              pattern="[0-9],*"
                              className={`${STYLE_INPUT_INSET} w-full`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {otherLists?.length > 0 && (
          <div className="w-full pb-2.5 border-b border-gray60">
            <div className="flex flex-col gap-1.5">
              <div className="text-sm text-mainblue font-medium mb-1.5">
                อื่นๆ
              </div>
              <div className="flex flex-col gap-1.5">
                {/*---- อื่นๆ ----*/}
                {otherLists?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full flex flex-row justify-between "
                    >
                      <div className="my-2.5 w-full ">
                        <div className={STYLE_BODY_INVEST}>
                          <div className=" flex gap-[6px] items-center">
                            {row?.title}
                          </div>
                          <div
                            className={"flex  md:w-40  w-28 h-8  justify-start"}
                          >
                            {row?.current_price}
                          </div>
                          <div className=" flex items-center justify-end w-full ">
                            <NumericFormat
                              maxLength={11}
                              value={row?.return_interest}
                              placeholder="0"
                              onChange={(e) =>
                                onChangeInterestPlace(
                                  e.target.value,
                                  index,
                                  "debt_long_others",
                                  "debt_long_other"
                                )
                              }
                              thousandSeparator=","
                              pattern="[0-9],*"
                              className={`${STYLE_INPUT_INSET} w-full`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListPrivateMain;
