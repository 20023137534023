/* eslint-disable jsx-a11y/alt-text */
import { Drawer } from "@material-tailwind/react";
import { CardMain } from "../../../components/card/CardMain";

import {
  STYLE_INPUT,
  STYLE_INPUT_ERROR,
  STYLE_INPUT_SLIDER,
  STYLE_SPACE_Y,
} from "../../../utils/useStyle";
import { MTButton } from "../../../components/button/MTButton";
import { useEffect, useState } from "react";
import { MiltiSlider } from "../../../components/slider/Slider.Main";
import { ICON_NAVIGATE_RIGHT } from "../../../utils/useIcons";

import { SliderSingle } from "../../../components/slider/SliderSingle";
import ErrorMain from "../../../components/error/Error.Main";
import { useRetireStore } from "../../../_store/retireStore";
import { NumericFormat } from "react-number-format";
import { InfoDrawer } from "../../../components/drawer/InfoDrawer";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

export const DrawerSalary = ({ openSalary, setOpenSalary }) => {
  return (
    <InfoDrawer
      open={openSalary}
      head="เงินเดือน"
      text={
        "ค่าจ้างหรือค่าตอบแทนที่บริษัทจ่ายให้กับผู้ปฏิบัติงาน ซึ่งจ่ายเป็นประจำทุกเดือน เพื่อตอบแทนการปฏิบัติหน้าที่ตามความรับผิดชอบ จูงใจให้พนักงานปฏิบัติงานได้อย่างเต็มประสิทธิภาพ มีการใช้ความรู้ความสามารถทำงานให้กับองค์กรได้อย่างเต็มที่"
      }
      onClose={() => {
        setOpenSalary(false);
      }}
    />
  );
};

export const AgeInfo = ({ openAgeInfo, setOpenAgeInfo }) => {
  return (
    <InfoDrawer
      open={openAgeInfo}
      head="อายุ"
      text={
        "อายุปัจจุบันคืออายุของคุณในปัจจุบัน และอายุเกษียณคืออายุที่ครบกําหนดอายุรับราชการหรือสิ้นกําหนดเวลารับราชการ โดยเฉลี่ยอยู่ที่ 60ปี และอายุขัยคืออายุ ที่คาดว่าจะมีชีวิตอยู่ได้อีก โดยเฉลี่ยอยู่ที่ 80 ปี"
      }
      onClose={() => {
        setOpenAgeInfo(false);
      }}
    />
  );
};

export const SalaryPerYearInfo = ({ openSalaryInfo, setOpenSalaryInfo }) => {
  return (
    <InfoDrawer
      open={openSalaryInfo}
      head="เงินเดือนที่เพิ่มขึ้นต่อปี"
      text={
        " ค่าจ้างหรือค่าตอบแทนที่บริษัทจ่ายให้กับผู้ปฏิบัติงาน ซึ่งจ่ายเป็นประจำทุกเดือน โดยเปอร์เซ็นต์ของเงินเดือนที่เพิ่มขึ้นต่อปี โดยเฉลี่ย ประมาณ 3-5% ต่อปี "
      }
      onClose={() => {
        setOpenSalaryInfo(false);
      }}
    />
  );
};

const numberWithoutComma = (value) => {
  let result = parseInt(value?.replace(/,/g, ""), 10);
  return result;
};

const ViewProfile = ({
  onClickSalary,
  onClickSalaryPerYear,
  onClick,
  formRetire,
  setFormRetire,
  error,
  setError,

  setOpenAgeInfo,
  setOpenSalary,

  setOpenSalaryInfo,
}) => {
  const retireData = useRetireStore((state) => state.retireData);
  const setRetireData = useRetireStore((state) => state.setRetireData);

  const onChangeCurrentAge = (e) => {
    const value = Number(e.target.value) ? Number(e.target.value) : null;

    if (isNaN(e.target.value)) return;

    setAge(Number(e?.target?.value));
    setFormRetire({
      ...formRetire,
      age_current: value,
      // age_current: e[0],
    });
    // setFormRetire({
    //   ...formRetire,
    //   age_current: e[0],
    //   age_retire: e[1],
    //   age_dead: e[2],
    // });
  };

  const onChangeRetireAge = (e) => {
    const value = Number(e.target.value) ? Number(e.target.value) : null;
    if (value > 120) return;

    if (isNaN(e.target.value)) return;
    setFormRetire({
      ...formRetire,
      age_retire: value,
      strategy_age_retire: value,
    });
  };

  const onChangeDeadAge = (e) => {
    const value = Number(e.target.value) ? Number(e.target.value) : null;

    if (isNaN(e.target.value)) return;
    setFormRetire({
      ...formRetire,
      age_dead: value,
      strategy_age_dead: value,
    });
  };

  const onChangeSalary = (e) => {
    const value = e.target.value;
    let outputSalary = numberWithoutComma(value);
    // Default Out Come
    let outputOutCome = outputSalary * 0.8;

    if (value > 100000000) {
      return;
    } else {
      setError({
        ...error,
        salary: false,
        out_come: false,
      });
    }

    if (value) {
      setFormRetire({
        ...formRetire,
        salary: outputSalary,
        out_come: outputOutCome,
      });
    } else {
      setFormRetire({
        ...formRetire,
        salary: "",
        out_come: 0,
      });
    }
  };

  const onChangeSalaryPerYear = (e) => {
    if (isNaN(e.target.value)) return;
    if (Number(e.target.value) > formRetire?.salary_peryear_max) return;
    else {
      setError({
        ...error,
        salary_peryear: false,
      });
    }

    setFormRetire({
      ...formRetire,
      salary_peryear: e.target.value ? Number(e.target.value) : null,
      strategy_salary_peryear: e.target.value ? Number(e.target.value) : null,
    });
    setRetireData({
      ...formRetire,
      salary_peryear: e.target.value ? Number(e.target.value) : null,
      strategy_salary_peryear: e.target.value ? Number(e.target.value) : null,
    });
  };

  const onChangeSalaryPerYearMax = (e) => {
    if (isNaN(e.target.value)) return;
    if (e.target.value > 20) return;
    if (e.target.value < formRetire?.salary_peryear) return;
    else {
      setError({
        ...error,
        salary_peryear_max: false,
      });
    }
    setFormRetire({
      ...formRetire,
      salary_peryear_max: e.target.value ? Number(e.target.value) : null,
    });
    setRetireData({
      ...formRetire,
      salary_peryear_max: e.target.value ? Number(e.target.value) : null,
    });
  };

  const onChangeMaxDeadAge = (e) => {
    if (isNaN(e.target.value)) return;

    setFormRetire({ ...formRetire, age_dead_max: Number(e.target.value) });
  };

  const [age, setAge] = useState([
    formRetire?.age_current,
    formRetire?.age_retire,
    formRetire?.age_dead,
  ]);

  const onChangeAge = (e) => {
    // console.log("AA:", e);
    setAge(e);
    setFormRetire({
      ...formRetire,
      age_current: e[0],
      age_retire: e[1],
      age_dead: e[2],
    });
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setFormRetire({
        ...formRetire,
        salary_peryear: formRetire?.salary_peryear_max,
      });
    }
  }, [formRetire?.salary_peryear_max]);

  // console.log("retireData", retireData);

  const AgeInformation = () => {
    return <div></div>;
  };

  return (
    <div className={STYLE_SPACE_Y}>
      <CardMain
        onClick={() => {
          setOpenAgeInfo(true);
        }}
        icon={true}
        title="อายุ"
        panel={
          <div className=" px-[7px] ">
            <div className=" grid grid-cols-3 justify-center items-center">
              <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                <img
                  src="/images/present_age2.png"
                  className=" mx-auto w-[50px] h-[50px]"
                />
                <div className="mt-2 text-sm font-semibold text-maincyan">
                  อายุปัจจุบัน
                </div>
               <NumericFormat
                  maxLength={3}
                  placeholder="0"
                  pattern="[0-9],*"
                  className={STYLE_INPUT_SLIDER}
                  value={formRetire.age_current}
                  onChange={onChangeCurrentAge}
                />
              </div>
              <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                <img
                  src="/images/old_age2.png"
                  className=" mx-auto w-[50px] h-[50px]"
                />
                <div className="mt-2 text-sm font-semibold text-[#B95940]">
                  อายุเกษียณ
                </div>
              <NumericFormat
                  maxLength={3}
                  placeholder="0"
                  pattern="[0-9],*"
                  className={STYLE_INPUT_SLIDER}
                  value={formRetire.age_retire}
                  onChange={onChangeRetireAge}
                />
              </div>
              <div className=" mx-auto flex flex-col justify-center items-center gap-1">
                <img
                  src="/images/dead2.png"
                  className=" mx-auto w-[50px] h-[50px]"
                />
                <div className="mt-2 text-sm font-semibold text-mainorange">
                  สิ้นอายุขัย
                </div>
               <NumericFormat
                  maxLength={3}
                  placeholder="0"
                  pattern="[0-9],*"
                  className={STYLE_INPUT_SLIDER}
                  value={formRetire?.age_dead}
                  onChange={onChangeDeadAge}
                />
              </div>
            </div>
            <div className=" pt-4 w-full">
              <MiltiSlider
                onChange={onChangeAge}
                current={formRetire?.age_current}
                retire={formRetire?.age_retire}
                dead={formRetire?.age_dead}
                maxdead={120}
              />
            </div>
          </div>
        }
      />
      <CardMain
        onClick={() => {
          setOpenSalary(true);
        }}
        icon={false}
        title="เงินเดือน (บาท/เดือน)"
        margin=""
        mb="mb-[21px]"
        panel={
          <div className=" px-2">
           <NumbericValidateRed
              value={formRetire?.salary}
              onChange={onChangeSalary}
              maxLength={11}
              placeholder="25,000"
               
              className={error?.salary ? STYLE_INPUT_ERROR : STYLE_INPUT}
            />
            {error?.salary && (
              <ErrorMain errortext="กรุณากรอกเงินเดือน ถ้าไม่มีเงินเดือนกรุณากรอกเลข 0" />
            )}
          </div>
        }
      />
      <CardMain
        onClick={() => {
          setOpenSalaryInfo(true);
        }}
        icon={false}
        title="เงินเดือนที่เพิ่มขึ้นต่อปี (%)"
        margin=""
        mb="mb-2.5"
        panel={
          <div className=" px-4">
            <div className=" flex justify-center">

             <NumbericValidateRed
                maxLength={11}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                placeholder="0"
                className={STYLE_INPUT_SLIDER}
                value={formRetire?.salary_peryear}
                onChange={onChangeSalaryPerYear}
              />
            </div>
            <SliderSingle
              placeholder="0"
              valuemax={formRetire?.salary_peryear_max}
              value={formRetire?.salary_peryear}
              onChange={onChangeSalaryPerYear}
              onChnageMax={onChangeSalaryPerYearMax}
            />
            {error?.salary_peryear && (
              <ErrorMain errortext="กรุณากรอกเงินเดือนที่เพิ่มขึ้นต่อปี ถ้าไม่มีเงินเดือนกรุณากรอกเลข 0" />
            )}
            {error?.salary_peryear_max && (
              <ErrorMain errortext="กรุณากรอกเงินเดือนที่เพิ่มขึ้นต่อปี" />
            )}
          </div>
        }
      />
    </div>
  );
};
export default ViewProfile;
