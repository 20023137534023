import { NumericFormat } from "react-number-format";
import { CardMain } from "../../../components/card/CardMain";
import {
  STYLE_INPUT,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_BLUE,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { useState } from "react";
import numeral from "numeral";
import { CheckBoxSecond } from "../../../components/checkbox/CheckboxSecond";
import { RadioMain } from "../../../components/radio/Radio.Main";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { defaultValue } from "../../../functions/main";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const ViewFamily_StepThree = ({ onChangeForm }) => {
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  return (
    <div>
      <div className={`${STYLE_PADDING_MAIN} my-3 space-y-5 `}>
        {/* <div className={`${STYLE_TEXT_CYAN} text-center`}>
          มูลค่าทรัพย์สินปัจจุบัน
        </div> */}

        <CardMain
          title={"สินทรัพย์รวม (ยกเว้นสินทรัพย์ส่วนตัว เช่น บ้านที่อยู่อาศัย รถยนต์ที่ใช้ส่วนตัว)"}
          classNameLine={"mt-2"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
               <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  onChange={onChangeForm("asset_all")}
                  value={insuranceData?.asset_all}
                />
              </div>
            </div>
          }
        />

        <CardMain
          title={"ทุนประกันชีวิตส่วนตัว"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
               <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  value={insuranceData?.asset_insurance_private}
                  onChange={onChangeForm("asset_insurance_private")}
                />
              </div>
            </div>
          }
        />
        <CardMain
          title={"ทุนประกันชีวิตกลุ่ม"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
               <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  value={insuranceData?.asset_insurance_group}
                  onChange={onChangeForm("asset_insurance_group")}
                />
              </div>
            </div>
          }
        />
        <CardMain
          title={"เงินกองทุนสำรองเลี้ยงชีพ"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
               <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  value={insuranceData?.asset_invest_rmf}
                  onChange={onChangeForm("asset_invest_rmf")}
                />
              </div>
            </div>
          }
        />
        <CardMain
          title={"ท่านมีประกันสังคมหรือไม่ ?"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" flex items-center">
                <RadioMain
                  name="asset_is_social_insurance"
                  value="N"
                  checked={insuranceData.asset_is_social_insurance === "N"}
                  label={
                    <div className={"font-semibold text-black"}>ไม่มี</div>
                  }
                  onChange={onChangeForm("asset_is_social_insurance", "string")}
                />
                <RadioMain
                  name="asset_is_social_insurance"
                  value="Y"
                  checked={insuranceData.asset_is_social_insurance === "Y"}
                  label={<div className={"font-semibold text-black"}>มี</div>}
                  onChange={onChangeForm("asset_is_social_insurance", "string")}
                />
              </div>
            </div>
          }
        />
        {insuranceData?.asset_is_social_insurance === "Y" ? (
          <div className="space-y-5">
            <CardMain
              title={"เงินสงเคราะห์ประกันสังคมกรณีเสียชีวิต"}
              panel={
                <div className=" px-3 -mt-3 space-y-2.5">
                  <div className=" text-center mt-4  text-mainblue">
                    เงินทำศพจากประกันสังคม
                    <div className=" text-maincyan font-semibold text-xl">
                      {numeral(insuranceData.asset_social_dead || 40000).format(
                        "0,0"
                      )}{" "}
                      บาท
                    </div>
                  </div>
                </div>
              }
            />
            <CardMain
              title={"เงินคืนจากกองทุนประกันสังคมกรณีชราภาพ"}
              panel={
                <div className=" px-3 -mt-3 space-y-2.5">
                 <NumbericValidateRed
                    maxLength={11}
                    placeholder="25,000"
                    thousandSeparator=","
                    inputMode="decimal"
                    pattern="[0-9],*"
                    className={`${STYLE_INPUT} `}
                    value={insuranceData?.asset_social_retire}
                    onChange={onChangeForm("asset_social_retire")}
                  />
                  {/* <div className=" text-center mt-4  text-mainblue">
                    มูลค่าทรัพย์สินที่มีในปัจจุบัน
                    <div className=" text-maincyan font-semibold text-xl">
                      {numeral(
                        defaultValue(insuranceData?.asset_social_retire)
                      ).format("0,0")}{" "}
                      บาท
                    </div>
                  </div> */}
                </div>
              }
            />
          </div>
        ) : (
          ""
        )}
        <CardMain
          marginyellow={"mt-4"}
          title={
            <div>
              นำหนี้สินและทุนประกันศึกษาบุตรมา
              <div>คำนวณด้วยหรือไม่</div>
            </div>
          }
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" flex items-center">
                <RadioMain
                  name="asset_is_debt"
                  value="N"
                  checked={insuranceData.asset_is_debt === "N"}
                  label={
                    <div className={"font-semibold text-black"}>ไม่ใช่</div>
                  }
                  onChange={onChangeForm("asset_is_debt", "string")}
                />
                <RadioMain
                  name="asset_is_debt"
                  value="Y"
                  checked={insuranceData.asset_is_debt === "Y"}
                  label={<div className={"font-semibold text-black"}>ใช่</div>}
                  onChange={onChangeForm("asset_is_debt", "string")}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ViewFamily_StepThree;
