import { NumericFormat } from "react-number-format";
import { CardMain } from "../../../components/card/CardMain";
import {
  STYLE_BORDER_YELLOW_AC,
  STYLE_BORDER_YELLOW_PROPOTION,
  STYLE_INPUT,
  STYLE_INPUT_SLIDER,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { useState } from "react";
import numeral from "numeral";
import { ICON_ARROW_UP_DOWN } from "../../../utils/useIcons";
import { HeadtitleCard } from "../../../components/header/Header";
import { SliderSingle } from "../../../components/slider/SliderSingle";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { defaultValue } from "../../../functions/main";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const ViewFamily_StepTwo = ({ onChangeForm }) => {
  const [value, setValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCal, setIsOpenCal] = useState(false);
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  return (
    <div>
      <div className={`${STYLE_PADDING_MAIN} my-3 space-y-5 `}>
        <CardMain
          title={"คำนวณค่าใช้จ่ายสำหรับครอบครัว"}
          panel={
            <div>
              <div className=" px-3 space-y-1">
                <div className=" font-semibold text-maincyan text-center text-xl">
                  {numeral(
                    defaultValue(insuranceData.sum_family_expenses)
                  ).format("0,0")}{" "}
                  บาท
                </div>
                <div className=" text-mainblue text-center text-sm font-medium">
                  ค่าใช้จ่ายสำหรับครอบครัว
                </div>
              </div>
              <div className=" border-b border-gray60 w-full my-2.5"></div>
              <div
                onClick={() => setIsOpen(!isOpen)}
                className=" flex justify-center items-center gap-1 text-xs text-maingray font-medium"
              >
                ค่าใช้จ่ายส่วนตัว
                <ICON_ARROW_UP_DOWN
                  className={` ${
                    isOpen ? "rotate-180" : ""
                  } w-4 h-4 transition-all`}
                />
              </div>
              {isOpen && (
                <div className=" my-4">
                  <div className=" text-sm text-center  px-3 mb-2.5">
                    หากเสียชีวิตค่าใช้จ่ายส่วนตัวต่าง ๆ จะไม่เกิดขี้น ได้แก่
                  </div>
                  <div className=" space-y-2.5">
                    <HeadtitleCard title="เงินออมทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_saving}
                        onChange={onChangeForm("personal_expenses_saving")}
                      />
                    </div>
                    <HeadtitleCard title="ภาษีเงินได้" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_tax}
                        onChange={onChangeForm("personal_expenses_tax")}
                      />
                    </div>
                    <HeadtitleCard title="กองทุนสำรองเลี้ยงชีพทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_life_fund}
                        onChange={onChangeForm("personal_expenses_life_fund")}
                      />
                    </div>
                    <HeadtitleCard title="เงินประกันสังคมทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={
                          insuranceData?.personal_expenses_social_insurance
                        }
                        onChange={onChangeForm(
                          "personal_expenses_social_insurance"
                        )}
                      />
                    </div>
                    <HeadtitleCard title="ค่าใช้จ่ายอุปโภคทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_consumption}
                        onChange={onChangeForm("personal_expenses_consumption")}
                      />
                    </div>
                    <HeadtitleCard title="ค่าอาหารทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_food}
                        onChange={onChangeForm("personal_expenses_food")}
                      />
                    </div>
                    <HeadtitleCard title="ค่านันทนาการทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_entertainment}
                        onChange={onChangeForm(
                          "personal_expenses_entertainment"
                        )}
                      />
                    </div>
                    <HeadtitleCard title="ค่าเดินทางทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_travel}
                        onChange={onChangeForm("personal_expenses_travel")}
                      />
                    </div>
                    <HeadtitleCard title="ค่าเบี้ยประกันชีวิตทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_life_insurance}
                        onChange={onChangeForm(
                          "personal_expenses_life_insurance"
                        )}
                      />
                    </div>
                    <HeadtitleCard title="เงินผ่อนชำระงวดหนี้สินคงค้างทั้งปี" />
                    <div className=" px-3">
                     <NumbericValidateRed
                        maxLength={11}
                        placeholder="xx,xxx"
                        thousandSeparator=","
                        inputMode="decimal"
                        pattern="[0-9],*"
                        className={`${STYLE_INPUT} `}
                        value={insuranceData?.personal_expenses_debt}
                        onChange={onChangeForm("personal_expenses_debt")}
                      />
                    </div>
                  </div>
                  <div className=" text-center mt-4 font-medium">
                    รวมค่าใช้จ่ายที่จะไม่เกิดขึ้นหลังเสียชีวิต
                    <div className=" text-mainblue font-semibold text-xl">
                      {numeral(
                        defaultValue(insuranceData.sum_personal_expenses)
                      ).format("0,0")}{" "}
                      บาท
                    </div>
                  </div>
                  <div className="border-b border-mainyellow w-full my-2.5"></div>
                  <div className=" text-center mt-4">
                    <div className=" text-mainblue font-semibold text-xl">
                      ค่าใช้จ่ายสำหรับครอบครัว
                    </div>
                    <div className=" text-maincyan font-semibold text-2xl">
                      {numeral(
                        defaultValue(insuranceData.sum_income) -
                          defaultValue(insuranceData.sum_personal_expenses)
                      ).format("0,0")}{" "}
                      บาท
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        />
        <CardMain
          title={"คำนวณเงินเพื่อครอบครัว"}
          panel={
            <div className="">
              <div className=" px-3 space-y-1">
                <div className=" font-semibold text-maincyan text-center text-xl">
                  {numeral(
                    defaultValue(insuranceData.sum_family_remain, 0)
                  ).format("0,0")}{" "}
                  บาท
                </div>
                <div className=" text-mainblue text-center text-sm font-medium">
                  จำนวนเงินเพื่อครอบครัวคงเหลือ
                </div>
              </div>
              <div className=" border-b border-gray60 w-full my-2.5"></div>
              <div
                onClick={() => setIsOpenCal(!isOpenCal)}
                className=" flex justify-center items-center gap-1 text-xs text-maingray font-medium"
              >
                รายละเอียด
                <ICON_ARROW_UP_DOWN
                  className={` ${
                    isOpenCal ? "rotate-180" : ""
                  } w-4 h-4 transition-all`}
                />
              </div>
              {isOpenCal && (
                <div>
                  <div className=" space-y-2.5 my-4">
                    <div className="mb-3">
                      <HeadtitleCard
                        title={
                          <div className="">
                            จำนวนทุนประกันขั้นต่ำที่ต้องการให้ครอบครัวอยู่รอด 5
                            ปี
                          </div>
                        }
                        marginyellow={"mt-1 ทิ"}
                      />
                      <div className="mb-10 -mt-3 px-3 text-maincyan font-semibold text-center">
                        {numeral(
                          (defaultValue(insuranceData.sum_income) -
                            defaultValue(insuranceData.sum_personal_expenses)) *
                            5
                        ).format("0,0")}{" "}
                        บาท
                      </div>
                    </div>

                    <HeadtitleCard
                      title={
                        <div>
                          จำนวนปีที่ต้องการให้ครอบครัวอยู่รอด
                          <div>เสมือนท่านยังอยู่</div>
                        </div>
                      }
                      marginyellow={"mt-4"}
                    />
                    <div className=" px-3">
                      <div className=" flex justify-center">
                       <NumbericValidateRed
                          maxLength={11}
                          placeholder="จำนวน ปี"
                          // thousandSeparator=","
                          inputMode="decimal"
                          pattern="[0-9]*"
                          className={STYLE_INPUT_SLIDER}
                          value={insuranceData?.family_year_survive}
                          onChange={onChangeForm("family_year_survive")}
                        />
                      </div>
                      <SliderSingle
                        valuemax={insuranceData?.family_year_survive_max}
                        value={insuranceData?.family_year_survive}
                        onChange={onChangeForm("family_year_survive")}
                        onChnageMax={onChangeForm("family_year_survive_max")}
                        year={true}
                      />
                      <div className=" space-y-2.5 mt-3">
                        <div className=" text-sm text-center text-mainblue font-medium">
                          จะได้จำนวนเงินเพื่อคุ้มครองรายได้ของครอบครัว
                        </div>
                        <div className=" text-maincyan text-center font-semibold">
                          {numeral(insuranceData.sum_family).format("0,0")} บาท
                        </div>
                      </div>
                    </div>
                    <HeadtitleCard title="โดยปกติรายได้ท่านเพิ่มขึ้นปีละ (%)" />
                    <div className=" px-3">
                      <div>
                        <div className=" flex justify-center">
                         <NumbericValidateRed
                            maxLength={2}
                            max={insuranceData?.family_income_rate_max}
                            placeholder="0"
                            // thousandSeparator=","
                            inputMode="decimal"
                            pattern="[0-9],*"
                            className={STYLE_INPUT_SLIDER}
                            value={insuranceData?.family_income_rate}
                            onChange={onChangeForm("family_income_rate")}
                          />
                        </div>
                        <SliderSingle
                          valuemax={insuranceData?.family_income_rate_max}
                          value={insuranceData?.family_income_rate}
                          onChange={onChangeForm("family_income_rate")}
                          onChnageMax={onChangeForm("family_income_rate_max")}
                        />
                      </div>
                      <div className=" space-y-2.5 mt-3">
                        <div className=" text-sm text-center text-mainblue font-medium">
                          เพื่อให้ครอบครัวอยู่ได้ถึงที่ท่านต้องการ
                          <div>ท่านต้องมีเงิน</div>
                        </div>
                        <div className=" text-maincyan text-center font-semibold">
                          {numeral(insuranceData.sum_family_survive).format(
                            "0,0"
                          )}{" "}
                          บาท
                        </div>
                      </div>
                    </div>
                    <HeadtitleCard title="ครอบครัวสามารถลงทุนได้ผลตอบแทนต่อปี (%)" />
                    <div className=" px-3">
                      <div>
                        <div className=" flex justify-center">
                         <NumbericValidateRed
                            maxLength={2}
                            max={insuranceData?.family_investment_rate_max}
                            placeholder="0"
                            // thousandSeparator=","
                            inputMode="decimal"
                            pattern="[0-9],*"
                            className={STYLE_INPUT_SLIDER}
                            value={insuranceData?.family_investment_rate}
                            onChange={onChangeForm("family_investment_rate")}
                          />
                        </div>
                        <SliderSingle
                          valuemax={insuranceData?.family_investment_rate_max}
                          value={insuranceData?.family_investment_rate}
                          onChange={onChangeForm("family_investment_rate")}
                          onChnageMax={onChangeForm("family_investment_rate_max")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" text-center mt-4">
                    <div className=" text-mainblue font-semibold text-xl">
                      จำนวนเงินเพื่อครอบครัวคงเหลือ
                    </div>
                    <div className=" text-maincyan font-semibold text-2xl">
                      {numeral(
                        defaultValue(insuranceData.sum_family_remain, 0)
                      ).format("0,0")}{" "}
                      บาท
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ViewFamily_StepTwo;
