import { NumericFormat } from "react-number-format";
import { CardMain } from "../../../components/card/CardMain";
import {
  STYLE_INPUT,
  STYLE_PADDING_MAIN,
  STYLE_TEXT_CYAN,
} from "../../../utils/useStyle";
import { useState } from "react";
import numeral from "numeral";
import { useInsuranceStore } from "../../../_store/insuranceStore";
import { defaultValue } from "../../../functions/main";
import NumbericValidateRed from "../../../components/DebtBalance/numericRed";

const ViewFamily_StepOne = ({ onChangeForm }) => {
  const insuranceData = useInsuranceStore((state) => state.insuranceData);

  return (
    <div>
      <div className={`${STYLE_PADDING_MAIN} my-3 space-y-5 `}>
        <div className={`${STYLE_TEXT_CYAN} text-center`}>
          รายได้ที่เกิดจากการทำงาน
        </div>
        <CardMain
          title={"เงินเดือน (ต่อเดือน)"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
               <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  onChange={onChangeForm("income_salary")}
                  value={insuranceData?.income_salary}
                />
                <div className=" text-center font-medium text-mainblue pt-[14px] ">
                  เงินเดือนรวม (ต่อปี)
                  <span className={`text-maincyan font-semibold mx-1`}>
                    {insuranceData?.income_salary_per_year
                      ? numeral(insuranceData?.income_salary_per_year)?.format(
                          "0,0"
                        )
                      : ""}
                  </span>
                  บาท
                </div>
              </div>
            </div>
          }
        />
        <CardMain
          title={"รายได้จากโบนัสและค่านายหน้า (ต่อปี)"}
          panel={
            <div className=" px-3 -mt-3 space-y-2.5">
              <div className=" font-semibold text-mainblue text-center">
               <NumbericValidateRed
                  maxLength={11}
                  placeholder="25,000"
                  thousandSeparator=","
                  inputMode="decimal"
                  pattern="[0-9],*"
                  className={`${STYLE_INPUT} `}
                  onChange={onChangeForm("income_bonus")}
                  value={insuranceData?.income_bonus}
                />
              </div>
            </div>
          }
        />
      </div>
      {insuranceData.income_salary ? (
        <div className=" bg-gray50 w-full h-20 mb-5 flex flex-col justify-center">
          <div className=" text-center text-mainblue font-semibold text-xl ">
            รวมรายได้ส่วนตัว
          </div>
          <div className=" text-center text-maincyan font-semibold text-2xl ">
            {numeral(defaultValue(insuranceData?.sum_income, 0))?.format("0,0")}{" "}
            บาท
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ViewFamily_StepOne;
